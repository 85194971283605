import React, { useState } from "react";
import Plus from '../../../assets/Icons/plus.png'
import { AnimatedModal } from "../../../ReusableComponents/AnimatedModal";
import GoogleDriveIcon from "../../../../src/icons/drive.png"
import SelectImageSet from '../../../../src/assets/SelectImageset.png'
import SelectTiff from '../../../../src/assets/SelectTiff.png';
import ImportTiff from '../../../../src/assets/ImportTiff.png';
import ImportZip from "../../../../src/assets/zip-import.png"
import DroneNakshaMapsImporter from "./DroneNakshaMapsImporter";
import CloseButton from '../../../icons/closeButton.png';
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import AppConstants from "../../../AppConstants";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
import ZipImporter from "../../../ReusableComponents/ZipImporter";
import ModeSelector from "../../../ReusableComponents/ModeSelector";
import LOCAL from "../../../../src/icons/local.png"
import GDRIVE from "../../../../src/icons/gdrive.png"

const ImagesTypeSelection = (props) => {
    const [showDronNakshaMaps, setShowDronNakshaMaps] = useState(false)
    const [selectImagePopup, setSelectImagePopup] = useState(false)
    const [loaderMsg, setLoaderMsg] = useState(null)
    const [showProcessPopup, setShowProcessPopup] = useState(false)
    const [selectedApplicationType, setSelectedApplicationType] = useState(undefined);
    const [fileTypeForInput, setFileTypeForInput] = useState(undefined);
    const [showModeSelector, setShowModeSelector] = useState(false)
    const [modeSelectorData, setModeSelectorData] = useState({ heading: "", modes: [] })
    const applicationTypeList = [
        {
            name: AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.NAME,
            onClick: () => {
                setSelectedApplicationType(AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.NAME);
                setFileTypeForInput(".zip");
                setShowProcessPopup(true);
                setSelectImagePopup(false);
            }
        }
    ]

    const TypeSelection = <div style={{ padding: '10px 10px' }}>

        <div style={{ display: 'flex', color: "#212529", justifyContent: "center" }}>
            {props.droneNakshaCards && <>
                {/* normal images */}
                <div className='taskSelectionCard' style={{ margin: '3%', width: props.disableOrthomosaic ? "192px" : "200px", height: "" }}
                    onClick={() => {
                        setSelectImagePopup(false);
                        props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG);
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={SelectImageSet}
                            style={{
                                margin: "0px 10px 10px 10px",
                                height: "100px",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
                        Upload Images
                    </div>
                    <div style={{ textAlign: "center", fontSize: "12px" }}>
                        JPEG or PNG image
                    </div>

                </div>
                {/* import from google drive */}
                <div className='taskSelectionCard' style={{ margin: '3%', width: props.disableOrthomosaic ? "192px" : "200px", height: "" }}
                    onClick={() => {
                        props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG, "GDRIVE")
                        setFileTypeForInput(".jpg, .jpeg, .png");
                        setSelectImagePopup(false);
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={GoogleDriveIcon}
                            style={{
                                margin: "10px 20px 10px",
                                width: "70%",
                            }}
                        />
                    </div>
                    <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
                        Import from Google Drive
                    </div>
                    <div style={{ textAlign: "center", fontSize: "12px" }}>
                        JPEG or PNG image
                    </div>
                </div>
            </>}
            {!props?.hideCards?.[0] && <div className='taskSelectionCard' style={{ margin: '3%', width: props.disableOrthomosaic ? "192px" : "200px", height: "", position: "relative" }}
                onClick={() => {
                    setShowModeSelector(true)
                    setModeSelectorData({
                        heading: "Select mode of upload",
                        modes: [{
                            text: "My computer", icon: LOCAL, onClick: () => {
                                props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG);
                                setFileTypeForInput(".jpg, .jpeg, .png");
                                setSelectImagePopup(false);
                            }
                        },
                        {
                            text: "Google drive", icon: GDRIVE, onClick: () => {
                                props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG, "GDRIVE");
                                setFileTypeForInput(".jpg, .jpeg, .png");
                                setSelectImagePopup(false);
                            }
                        }]
                    })
                }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src={SelectImageSet}
                        style={{
                            margin: "0px 10px 10px 10px",
                            height: "100px",
                            width: "100%",
                        }}
                    />
                </div>
                <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
                    Upload Images
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                    JPEG or PNG image
                </div>
            </div>}

            {props?.hideCards?.[1] || props.disableOrthomosaic ? <></> : <>
                <div className='taskSelectionCard' style={{ margin: '3%', width: "200px", height: "" }} // "taskSelectionCardDisabled" this class disabled card
                    onClick={() => {
                        props.onSelectTypeForUpload(AppConstants.FILETYPES.TIF)
                        setSelectImagePopup(false)
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={SelectTiff}
                            style={{
                                margin: "0px 10px 0px 10px",
                                height: "100px",
                                width: "100%"
                            }}
                        />
                    </div>
                    <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
                        Upload orthomosaic tiff
                    </div>
                    <div style={{ textAlign: "center", fontSize: "12px" }}>
                        COG compatible orthomosaic tiff
                    </div>
                </div>
                <div className='taskSelectionCard' style={{ margin: '3%', width: "200px", height: "" }}
                    onClick={() => setShowDronNakshaMaps(true)}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={ImportTiff}
                            style={{
                                margin: "0px 10px 0px 10px",
                                height: "100px",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
                        Import from DroneNaksha
                    </div>
                    <div style={{ textAlign: "center", fontSize: "12px" }}>
                        Import orthomosaic map from DroneNaksha
                    </div>
                </div> </>}

            {props?.hideCards?.[2] || props.disableApplicationType ? <></> :
                <div className='taskSelectionCard' style={{ margin: '3%', position: "relative", width: "192px", height: "" }}
                    onClick={() => {
                        setShowModeSelector(true)
                        setModeSelectorData({
                            heading: "Select application type",
                            modes: [{
                                text: "Windmill", onClick: () => {
                                    setSelectedApplicationType(AppConstants.PICSTORK_APPLICATION_TYPES.WINDMILL.NAME);
                                    setFileTypeForInput(".zip");
                                    setShowProcessPopup(true);
                                    setSelectImagePopup(false);
                                }
                            }]
                        })
                    }
                    }
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={ImportZip}
                            style={{
                                margin: "0px 10px 0px 10px",
                                height: "100px",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div style={{ margin: '5px 0px' }} className='taskSelectionTitle'>
                        Import ZIP
                    </div>
                    <div style={{ textAlign: "center", fontSize: "12px" }}>
                        ZIP Containing <br /> Images
                    </div>
                </div>}
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', margin: !(props.disableOrthomosaic) ? "-3px 0 0 -13px" : "5px 0 0 -10px" }}>
            <ButtonWithIcon
                text="cancel"
                width="80px"
                isBtnActive
                onClick={() => setSelectImagePopup(false)}
            />
        </div>
    </div >

    const onSelectMaps = (maps) => {
        if (maps) {
            setSelectImagePopup(false)
            setLoaderMsg("Importing maps from DroneNaksha...")
            props.importSelectedOrtho(maps)
        } else setShowDronNakshaMaps(false)
    }

    const closeActivityPopup = () => {
        setSelectedApplicationType(undefined)
        setShowProcessPopup(false);
    }

    const onSelectTypeForUploadZip = () => {
        setShowProcessPopup(false);
        document.getElementById("hiddenFileInputUpload").click();
    }

    return <>
        <ZipImporter
            uploadedImageCount={props.uploadedImageCount}
            fileType={props.uploadedImageCount > 0 ? "jpg, .jpeg, .png" : fileTypeForInput}
            collectionId={props.collectionId}
            selectedApplicationType={selectedApplicationType}
            onImageUpload={() => {
                // alert("file Uploaded 100 %");
                props.uploadOk()
            }}
        />

        <ActivityPopup
            width="940px"
            item={"WARNING"}
            open={showProcessPopup}
            icon={"WARNING"}
            // action={this.state.processAction}
            msg={"Make sure you are uploading .ZIP File. Do you want to continue ?"}
            close={() => closeActivityPopup()}
            onClickOk={() => {
                onSelectTypeForUploadZip()
            }}
        />
        {/* mode of upload selection animated modal */}

        <ModeSelector
            showModeSelector={showModeSelector}
            closeModeSelector={() => {
                setShowModeSelector(false)
            }}
            heading={modeSelectorData?.heading}
            modes={modeSelectorData.modes} />
        <AnimatedModal
            height={showDronNakshaMaps ? '530px' : "max-content"}
            width={props?.AnimatedModalStyle?.width || (showDronNakshaMaps ? '730px' : "900px")}
            zIndex={5}
            onRequestClose={() => {
                setSelectImagePopup(false)
                setShowDronNakshaMaps(false)
            }}
            isOpen={selectImagePopup}
        >
            {loaderMsg ? <LoaderComponent
                width="98%"
                height="85%"
                message={loaderMsg}
            /> : <>
                {showDronNakshaMaps ?
                    <>
                        <div style={{ position: 'absolute', margin: '12px 0px 0px 95%' }}>
                            <img onClick={() => setShowDronNakshaMaps(false)} src={CloseButton} style={{ cursor: 'pointer', height: '20px' }} />
                        </div>
                        <div className="services-content-title" style={{ width: '100%', height: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#EAEAEA', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                            Select DroneNaksha Map
                        </div>
                        <div style={{ padding: '10px 20px', height: '86%', width: '100%' }}>
                            <DroneNakshaMapsImporter
                                cardHeight="130px"
                                padding="10px 30px"
                                cardWidth="180px"
                                user={props.user}
                                cardGap="30px"
                                onClickBack={() => onSelectMaps(false)}
                                onSelectMaps={onSelectMaps}
                                permissions={props.permissions}
                            />
                        </div>
                    </> : TypeSelection}
            </>}

        </AnimatedModal>

        <ButtonWithIcon
            text="Images"
            icon={Plus}
            onClick={() => {
                if (props?.fromTrainingTask) {
                    if (props?.fromTrainingTask.isTiff) props.onSelectTypeForUpload(AppConstants.FILETYPES.TIF)
                    else props.onSelectTypeForUpload(AppConstants.FILETYPES.PNGJPEG)
                }
                else setSelectImagePopup(true)
            }}
            isDisabled={props.isDisabled}
        />
    </>

}

export default ImagesTypeSelection