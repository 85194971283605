import React, { Component } from "react";
import * as API from "../../api.js";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';
import DeleteIcon from '../assets/sidebarIcons/delete.svg';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Loader from '../../icons/loader.svg';
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import { addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions.js";
import { DRONENAKSHA_FEATURES, getFeaturesPermissions } from "../../Teams/index.js";
import AppConstants, { BLOB_URL, tilesServer } from "../../AppConstants.js";


class aeroFarmPlantHealthReport extends Component {
    state = {
        isOwner: false,
        sharedBy: null,
        user: {},
        type: "",
        orthoImageData: {},
        plan: {},
        project: {},
        reportData: [],
        showProcessPopup: false,
        loading: true,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        deleteReport_id: '',
        reportheader: {},
        reportTitle: '',
        reportSubTitle: '',
        company_logo: '',
        company_name: '',
        editHeader: false,
        company_logo1: undefined,
        titleEdit: false,
        subTitleEdit: false,
        processTileMin: -0.283,
        processTileMax: 0.181,
        outId: "",
        yieldAnalysis: null
    };


    uploadCompanyLogo = () => {
        const data = new FormData()
        data.append("file", this.state.company_logo1)
        API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
            console.log(uploadlogo.status)
            if (uploadlogo.status == 200) {
                this.setState((state) => ({
                    ...state,
                    companyLogo1: undefined,
                }), () => {
                    this.getReportHeader()
                })
            } else {
                console.log('error while uploading logo')
            }
        })
    }

    areaInKm = () => {
        let area = this.props.location.state.orthoImageData.orthomosaicDetails ? this.props.location.state.orthoImageData.orthomosaicDetails.area : 0
        area = Number(area.split(' ')[0])
        return (`${(area * 1 / 1000000).toFixed(4)} sq.km`)
    }

    updateReportHeader = () => {
        API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
            console.log(data);
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    editHeader: false
                }))
                this.getReportHeader();
            } else {
                this.getReportHeader();
            }
        })
    }

    getReportHeader = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'plant_health'
        API.getReportHeader(task_id, report_type).then((data) => {
            if (data.data == null) {
                console.log("call add report function", data.data)
                this.addReportHeader();
            } else {
                console.log("get report header data", data.data)
                this.setState((state) => ({
                    ...state,
                    reportheader: data.data,
                    reportTitle: data.data.title,
                    reportSubTitle: data.data.subtitle,
                    company_logo: data.data.company_logo ? `${data.data.company_logo}?${this.state.st}&t${new Date().getTime()}` : this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: data.data.name,
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }

    addReportHeader = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'plant_health'
        let title = this.state.orthoImageData.task_name
        let subtitle = this.state.plan.plan_name
        let company_logo = null
        // this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
        API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
            console.log("inside get report data", data)
            if (data.status == 201) {
                this.getReportHeader();
            } else {
                this.setState((state) => ({
                    ...state,
                    reportTitle: this.state.orthoImageData.task_name,
                    reportSubTitle: this.state.plan.plan_name,
                    company_logo: this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
                    company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
                }))
            }
        })
    }

    handleHistogramUpdate = (e) => {
        // this.setState((state) => ({
        //     ...state,

        // }))
        // alert(" handle histogram updated")
    }
    updateHistogram = () => {
        console.log(" update histogram")
        let taskId = this.state.orthoImageData.task_id;
        let sas = encodeURIComponent(this.state.st);
        let blobContainer = this.state.container;
        let count = 0
        this.state.reportData.map((report, key) => {
            let algorithm = report.algorithm_type
            let processTileType = report.filter_type
            let processTileMin = report.min
            let processTileMax = report.max
            API.getHistogram({ taskId, algorithm, processTileType, processTileMin, processTileMax, sas, blobContainer }).then((data) => {
                console.log("histogrsam data", data)
                this.state.reportData[key].statistics = data.statistics
                count++
                if (this.state.reportData.length == count) {
                    this.setState((state) => ({
                        ...state,
                        loading: false
                    }))
                }
            })
        })
    }


    getAllDNReport = () => {
        let task_id = this.state.orthoImageData.task_id
        let report_type = 'PLANTHEALTH'
        API.getAllDNReport(task_id, report_type).then((reportData) => {
            this.setState((state) => ({
                ...state,
                reportData: reportData.reportData,
            }), () => {
                if (this.state.reportData.length > 0) {
                    this.state.reportData.map((report, key) => {
                        this.state.reportData[key].visibleInReport = true
                        if (report.algorithm_type == "NDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Normalized Difference Vegetation Index.",
                                description: 'Normalized Difference Vegetation Index shows the amount of green vegetation.'
                            }
                        } else if (report.algorithm_type == "NDVI (Blue)") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Normalized Difference Vegetation Index Blue.",
                                description: 'Normalized Difference Vegetation Index shows the amount of green vegetation.'
                            }
                        } else if (report.algorithm_type == "ENDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Enhanced Normalized Difference Vegetation Index.",
                                description: 'Enhanced Normalized Difference Vegetation Index is like NDVI, but uses Blue and Green bands instead of only Red to isolate plant health.'
                            }
                        } else if (report.algorithm_type == "vNDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Visible Normalized Difference Vegetation Index.",
                                description: 'Visible NDVI is an un-normalized index for RGB sensors using constants derived from citrus, grape, and sugarcane crop data.'
                            }
                        } else if (report.algorithm_type == "VARI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Visual Atmospheric Resistance Index.",
                                description: 'Visual Atmospheric Resistance Index shows the areas of vegetation.'
                            }
                        } else if (report.algorithm_type == "EXG") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Excess Green Index.",
                                description: 'Excess Green Index (derived from only the RGB bands) emphasizes the greenness of leafy crops such as potatoes.'
                            }
                        } else if (report.algorithm_type == "TGI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Triangular Greenness Index.",
                                description: 'Triangular Greenness Index (derived from only the RGB bands) performs similarly to EXG but with improvements over certain environments.'
                            }
                        } else if (report.algorithm_type == "BAI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Burn Area Index.",
                                description: 'Burn Area Index hightlights burned land in the red to near-infrared spectrum.'
                            }
                        } else if (report.algorithm_type == "GLI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Green Leaf Index.",
                                description: 'Green Leaf Index shows greens leaves and stems.'
                            }
                        } else if (report.algorithm_type == "GNDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Green Normalized Difference Vegetation Index.",
                                description: 'Green Normalized Difference Vegetation Index is similar to NDVI, but measures the green spectrum instead of red.'
                            }
                        } else if (report.algorithm_type == "GRVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Green Ratio Vegetation Index.",
                                description: 'Green Ratio Vegetation Index is sensitive to photosynthetic rates in forests.'
                            }
                        } else if (report.algorithm_type == "SAVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Soil Adjusted Vegetation Index.",
                                description: 'Soil Adjusted Vegetation Index is similar to NDVI but attempts to remove the effects of soil areas using an adjustment factor (0.5)'
                            }
                        } else if (report.algorithm_type == "MNLI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Modified Non-Linear Index.",
                                description: 'Modified Non-Linear Index improves the Non-Linear Index algorithm to account for soil areas.'
                            }
                        } else if (report.algorithm_type == "MSR") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Modified Simple Ratio.",
                                description: 'Modified Simple Ratio is an improvement of the Simple Ratio (SR) index to be more sensitive to vegetation.'
                            }
                        } else if (report.algorithm_type == "RDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Renormalized Difference Vegetation Index.",
                                description: 'Renormalized Difference Vegetation Index uses the difference between near-IR and red, plus NDVI to show areas of healthy vegetation.'
                            }
                        } else if (report.algorithm_type == "TDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Transformed Difference Vegetation Index.",
                                description: 'Transformed Difference Vegetation Index highlights vegetation cover in urban environments.'
                            }
                        } else if (report.algorithm_type == "OSAVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Optimized Soil Adjusted Vegetation Index.",
                                description: 'Optimized Soil Adjusted Vegetation Index is based on SAVI, but tends to work better in areas with little vegetation where soil is visible.'
                            }
                        } else if (report.algorithm_type == "LAI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Leaf Area Index.",
                                description: 'Leaf Area Index estimates foliage areas and predicts crop yields.'
                            }
                        } else if (report.algorithm_type == "EVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Enhanced Vegetation Index.",
                                description: 'Enhanced Vegetation Index is useful in areas where NDVI might saturate, by using blue wavelengths to correct soil signals.'
                            }
                        } else if (report.algorithm_type == "GCI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Green Chlorophyll Index",
                                description: 'GCI used to estimate leaf chlorophyll content in the plants based on near-infrared and green bands.'
                            }
                        } else if (report.algorithm_type == "RCI") {    // 
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Ratio Chlorophyll Index.",
                                description: 'incorporates the same chlorophyll content knowledge base as GCI and reflects it via the red multispectral band.'
                            }
                        } else if (report.algorithm_type == "BNDVI") {
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "Blue Normalized Difference Vegetation Index.",
                                description: 'GCI used to estimate leaf chlorophyll content in the plants based on near-infrared and green bands.'
                            }
                        } else { //SIPI2
                            this.state.reportData[key].algorithmDes = {
                                fullForm: "tructure Intensive Pigment Index 2 ",
                                description: 'tructure Intensive Pigment Index 2 '
                            }
                        }
                    })
                    this.updateHistogram()
                } else {
                    console.log("no data found in report.")
                    this.setState((state) => ({
                        ...state,
                        loading: false
                    }))
                }
            })
        })
    }


    deleteDNReport = () => {
        this.setState((state) => ({
            ...state,
            showAddImagePopup: false,
            itemName: "Customise report item",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteDNReport(this.state.deleteReport_id).then((data) => {
            console.log(data)
            if (data.status == 200) {
                this.getAllDNReport()
                this.setState((state) => ({
                    ...state,
                    showAddImagePopup: false,
                    itemName: "Customise report item",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    showAddImagePopup: false,
                    itemName: "Customise report item",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                }))
            }
        })
    }

    checkedTrue(index) {
        console.log("in checked fuydjgkdfgds", index)
        let temp = this.state.reportData
        temp.map((report, key) => {
            if (index === key)
                temp[key].visibleInReport = !(report.visibleInReport)
        })
        this.setState((state) => ({
            ...state,
            reportData: temp
        }))
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    // changeReportTitle = (event) => {
    //     let title = event.target.value
    //     this.setState((state) => ({
    //         ...state,
    //         reportTitle: title,
    //     }))
    // }

    // changeReportSubTitle = (event) => {
    //     let subTitle = event.target.value
    //     this.setState((state) => ({
    //         ...state,
    //         reportSubTitle: subTitle,
    //     }))
    // }

    // changeCompanyName = (event) => {
    //     let companyName = event.target.value
    //     this.setState((state) => ({
    //         ...state,
    //         company_name: companyName,
    //     }))
    // }

    handleChange = (e) => {
        let t = e.target;
        if (t.value === ' ') {
            console.log('space not allow');
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [t.name]: t.value,
            }))
        }

    }

    changeCompanyLogo = (event) => {
        // let companyLogo = event.target.value
        let targetFilesArr = [];
        targetFilesArr = Array.from(event.target.files);
        let companyLogo = targetFilesArr[0]
        this.setState((state) => ({
            ...state,
            company_logo1: companyLogo,
        }), () => {
            this.uploadCompanyLogo()
        })
    }
    isUserOwnerOfCollection = () => {
        const { collection_id } = this.state.orthoImageData
        const { id } = this.state.project
        API.isUserProjectOwner(id, collection_id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
                console.log(result)
            })
            .catch((err) => {
                window.alert("Something Went Wrong")
            })
    }


    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        getFeaturesPermissions([DRONENAKSHA_FEATURES.REPORTS]).then(permissions => {
            this.setState({
                permissions,
                st: permissions?.st,
                container: permissions?.container,
                avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`
            }, () => {
                addUserAnalytic(this.props.location.pathname)
                    .then(id => {
                        console.log("ID : ", id)
                        this.setState((state) => ({
                            outId: id
                        }))
                    })
                if (this.props.location.state) {
                    let { user, type, orthoImageData, plan, project, yieldAnalysis } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        type,
                        orthoImageData,
                        plan,
                        project
                    }), async () => {
                        this.setState(({
                            yieldAnalysis: yieldAnalysis.length > 0 ? await this.formatYieldAnalysis(yieldAnalysis) : []
                        }))
                        document.title = `PlantHealth Report-${orthoImageData.task_name}`
                        this.isUserOwnerOfCollection()
                        this.getAllDNReport()
                        this.getReportHeader()
                    })
                } else {
                    API.getUserType().then(
                        ([blah, type, user]) => {
                            this.setState(
                                (state) => ({
                                    ...state,
                                    user,
                                    type,
                                })
                            );
                        },
                        (e) => {
                            window.location.replace("/login");
                        }
                    );
                }
            })
        })
            .catch(e => window.location.replace())

    }
    formatYieldAnalysis = (YA) => {
        return new Promise((resolve, reject) => {
            let array = []
            let temp = []
            YA.map((a, key) => {
                if (key > 0 && (key % 5 == 0 || key == YA.length - 1)) {
                    temp.push(a)
                    array.push(temp)
                    if (YA.length - 1 === key) {
                        console.log(array)
                        resolve(array)
                    }
                    temp = []
                } else {
                    temp.push(a)
                    if (YA.length - 1 === key) {
                        resolve([...array, temp])
                    }
                }

            })
        })
    }
    render() {
        let { user, type, task } = this.state;
        const handleClick = (event) => {
            document.getElementById("hiddenFileInput").click();
        };
        return (
            <>
                {this.state.loading ? <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> : <div>
                    <div className="top-bar" style={{ position: 'fixed', width: '100%' }}>
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/dronenaksha/projects",
                                    state: {
                                        type,
                                        user,
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }
                                },
                                {
                                    displayName: this.state.project.project_name,

                                    pathname: "/dronenaksha/project",
                                    state: {
                                        type,
                                        user,
                                        project: this.state.project,
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }

                                },
                                {
                                    displayName: this.state.plan.plan_name,

                                    pathname: "/dronenaksha/plan",
                                    state: {
                                        type,
                                        user,
                                        plan: this.props.location.state.plan,
                                        project: this.props.location.state.project
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }
                                },
                                {
                                    displayName: this.state.orthoImageData.task_name
                                }
                            ]} />
                            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                        </div>
                    </div>


                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteDNReport()}
                    />
                    {/* --------------------------------------------------------------- */}
                    <div style={{ display: 'flex', margin: '60px 15px', justifyContent: 'center' }}>
                        <div style={{ width: '77%' }}>
                            <div
                                id="pageprint1"
                                style={{
                                    padding: "20px 30px 20px 30px",
                                    width: "945px",
                                    margin: 'auto',
                                    boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                                }}
                            >
                                {/* <div id="buttonReport" className="buttonReport" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '7px -15 0px 0px' }} >
                                    <button style={{ marginRight: '20px' }} className="add-user-form-button-report" onClick={() => {
                                        this.setState(
                                            (state) => ({
                                                ...state,
                                                editHeader: !(this.state.editHeader),
                                            }), () => {
                                                if (this.state.editHeader == false) {
                                                    this.getReportHeader()
                                                }
                                            })
                                    }}>{this.state.editHeader ? "Cancel" : "Edit"}</button>
                                    {this.state.editHeader ? <button style={{}} className="add-user-form-button-report" onClick={() => { this.updateReportHeader() }}>
                                        Save
                                    </button> : <button style={{ opacity: '0.5' }} className="add-user-form-button-report">
                                        Save
                                    </button>}
                                </div> */}
                                <div className='page_Header'>
                                    <input
                                        name="company_logo"
                                        id="hiddenFileInput"
                                        type="file"
                                        className=""
                                        required
                                        onChange={this.changeCompanyLogo}
                                        accept='image/*'
                                        // multiple
                                        style={{ display: "none" }}
                                    />
                                    <div style={{ width: '885px', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                                        <div style={{}}>
                                            {this.state.titleEdit ? < div style={{ height: '47px' }}>
                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                                                <input maxLength={AppConstants.IMAGESET_NAME_LIMIT} type="text" value={this.state.reportTitle} name='reportTitle'
                                                    className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            titleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.updateReportHeader()
                                                        })
                                                }} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            titleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.getReportHeader()
                                                        })
                                                }} />
                                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                <div style={{ fontSize: '30px', fontWight: '500',maxWidth:"610px",overflow:"hidden",wordWrap:"break-word",lineHeight:0.8 }}>{this.state.reportTitle}</div>
                                                <img id="reportToolImage" className="reportToo9lImage" style={{ marginLeft: '10px', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={() => {
                                                    this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            titleEdit: true,
                                                            editHeader: true
                                                        }))
                                                }} />
                                            </div>
                                            }


                                            {this.state.subTitleEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
                                                <input maxLength={'20'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                                                    className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            subTitleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.updateReportHeader()
                                                        })
                                                }} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            subTitleEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.getReportHeader()
                                                        })
                                                }} />
                                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                <div style={{ fontSize: '20px', fontWight: '500',maxWidth:"580px",overflow:"hidden",wordWrap:"break-word",lineHeight:1 }}>{this.state.reportSubTitle}</div>
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={() => {
                                                    this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            subTitleEdit: true,
                                                            editHeader: true,
                                                        }))
                                                }} />
                                            </div>}
                                        </div>

                                        <div style={{}}>
                                            <div style={{ textAlign: 'end', }}>
                                                <img id="reportToolImage" className="reportToolImage" style={{ position: 'absolute', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && handleClick} />
                                                <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={this.state.company_logo} />
                                            </div>

                                            {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                                                <input maxLength={'20'} style={{ width: '200px' }} type="text" value={this.state.company_name} name='company_name'
                                                    className="edit-report-header-text" onChange={this.handleChange} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            companyNameEdit: false,
                                                            editHeader: false
                                                        }), () => {
                                                            this.updateReportHeader()
                                                        })
                                                }} />
                                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                                    this.setState(
                                                        (state) => ({
                                                            ...state,
                                                            companyNameEdit: false,
                                                            editHeader: false,
                                                        }), () => {
                                                            this.getReportHeader()
                                                        })
                                                }} />
                                            </div> :
                                                <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                                                    <div style={{ fontSize: '20px' }}>{this.state.company_name}</div>
                                                    <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", display: this.state.isOwner ? undefined : "none", cursor: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2" }} src={edit} onClick={() => {
                                                        this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                                            (state) => ({
                                                                ...state,
                                                                companyNameEdit: true,
                                                                editHeader: true,
                                                            }))
                                                    }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <hr style={{ width: '100%', margin: '5px 0px 15px 0px', height: '1px', padding: '0px' }} />
                                </div>

                                <div className="reportTable" style={{ display: 'flex', justifyContent: "center", padding: '20px 0px' }}>
                                    <table style={{ borderRadius: '4px', width: '80%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                        <col style={{ width: "50%" }} />
                                        <col style={{ width: "50%" }} />

                                        <tr style={{ border: '#EBEFF2', padding: '5px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '40px ', fontSize: '14px' }}>
                                            <th colspan="2" style={{ paddingLeft: '20px' }}>Map Details</th>
                                        </tr>

                                        <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '40px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Area</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.area ? this.areaInKm() : "NA") : "NA"}</td>
                                        </tr>
                                        {/* <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Images used</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.images_used ? this.state.orthoImageData.orthomosaicDetails.images_used : "NA") : "NA"}</td>
                                        </tr> */}
                                        <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '40px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>GSD</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.gsd ? this.state.orthoImageData.orthomosaicDetails.gsd : "NA") : "NA"}</td>
                                        </tr>
                                        {/* <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>DEM GSD</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.gsd_dem ? this.state.orthoImageData.orthomosaicDetails.gsd_dem : "NA") : "NA"}</td>
                                        </tr>
                                        <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Horizontal Accuracy </td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.horizontal_accuracy ? this.state.orthoImageData.orthomosaicDetails.horizontal_accuracy : "NA") : "NA"}</td>
                                        </tr>
                                        <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Vertical Accuracy</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.vertical_accuracy ? this.state.orthoImageData.orthomosaicDetails.vertical_accuracy : "NA") : "NA"}</td>
                                        </tr> */}
                                        <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '40px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Processing time</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.processing_time ? this.state.orthoImageData.orthomosaicDetails.processing_time : "NA") : "NA"}</td>
                                        </tr>
                                        <tr style={{ border: '#EBEFF2', borderStyle: 'solid', borderWidth: '2px', borderRadius: '4px', height: '40px ', fontSize: '14px' }}>
                                            <td style={{ paddingLeft: '20px', backgroundColor: '#ebebeb' }}>Processing date</td>
                                            <td style={{ paddingRight: '20px', paddingLeft: '20px' }}>{this.state.orthoImageData.orthomosaicDetails ? (this.state.orthoImageData.orthomosaicDetails.processing_date ? this.state.orthoImageData.orthomosaicDetails.processing_date : "NA") : "NA"}</td>
                                        </tr>
                                    </table>
                                </div>
                                {this.state.reportData.map((report, key) => {
                                    if (report.visibleInReport == true) {
                                        if (report.statistics) {
                                            console.log(report)
                                            return <>
                                                <div className="imagesPage" style={{ width: '100%', padding: '50px 0px', pageBreakBefore: "always", pageBreakInside: 'avoid' }}>
                                                    <label className="header" style={{ fontWeight: "bold", margin: '10px 0px 10px 0px' }}><h5>{report.algorithm_type} : {report.algorithmDes ? report.algorithmDes.fullForm : ''}</h5></label>
                                                    <p style={{ marginBottom: '10px', fontSize: "14px" }}>{report.algorithmDes ? report.algorithmDes.description : ''}</p>
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                        <div className="reportImage" style={{ width: '70%', border: 'solid 1px #EBEFF2', padding: '50px 15px', textAlign: 'center' }}>
                                                            <img style={{ maxWidth: '100%', maxHeight: '800px', margin: 'auto' }} src={`${tilesServer}/getPng/${this.state.orthoImageData.task_id}/plantHealth?sas=${encodeURIComponent(this.state.st)}&blobContainer=${this.state.container}&algorithm=${report.algorithm_type}&filterType=${report.filter_type}&min=${report.min}&max=${report.max}`} onError={(e) => { e.target.onerror = null; e.target.alt = 'Image Not Available' }} />
                                                        </div>
                                                        <div style={{ textAlign: 'center', fontSize: '10px', width: '30%', margin: '0px 0px 0px 10px' }}>
                                                            
                                                            <div style={{ margin: '0px 0px' }}>
                                                                <table style={{ width: '100%', borderRadius: '4px', }}>
                                                                    <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'left' }}>Min</th>
                                                                        <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.min}</td>
                                                                    </tr>
                                                                    <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'left' }}>Max</th>
                                                                        <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.max}</td>
                                                                    </tr>
                                                                    <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'left' }}>Algorithm Type</th>
                                                                        <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.algorithm_type}</td>
                                                                    </tr>
                                                                    <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'left' }}>Filter Type</th>
                                                                        <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.filter_type}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    }
                                })
                                }
                                {this.state.yieldAnalysis ?
                                    this.state.yieldAnalysis.map((YA) => {
                                        return <div className="reportTable" style={{ display: 'flex', justifyContent: "center", padding: '20px 0px', zIndex: 0, pageBreakBefore: "always" }}>
                                            <table className="customers-table" style={{ marginTop: '0px', borderRadius: "5px", width: "80%", zIndex: 0 }}>
                                                <col style={{ width: '40%' }} />
                                                <col style={{ width: '40%' }} />
                                                <col style={{ width: '20%' }} />
                                                <tr style={{ border: '2px solid #EBEFF2', padding: '5px', borderRadius: '4px', height: '38px ', fontSize: '14px' }}>
                                                    <th colspan="3" style={{ paddingLeft: '20px' }}>Plant Health Analysis</th>
                                                </tr>
                                                <tr className="customers-table-header-row" style={{ height: "40px", border: '2px solid #EBEFF2', borderRadius: '4px', background: "#EAEAEA" }}>
                                                    <th>Analysis</th>
                                                    <th>Percentage</th>
                                                    <th>Range</th>
                                                </tr>
                                                {
                                                    YA.map((yieldAnalysis, index) => {
                                                        return <>
                                                            <col style={{ width: '40%' }} />
                                                            <col style={{ width: '40%' }} />
                                                            <col style={{ width: '20%' }} />
                                                            <tr style={{ border: '2px solid #EBEFF2', padding: '5px', borderRadius: '4px', height: '38px ', fontSize: '12px' }}>
                                                                <th colspan="3" style={{ paddingLeft: '20px' }}>{yieldAnalysis.name}</th>
                                                            </tr>
                                                            {
                                                                yieldAnalysis.stats.map((stat, key) =>
                                                                    <tr className="customers-table-data-row" style={{ height: "40px", border: '2px solid #EBEFF2', borderRadius: '4px', }}>
                                                                        <td>{stat[1]}</td>
                                                                        <td>{stat[2]} %</td>
                                                                        <td>{stat[0]}</td>
                                                                    </tr>
                                                                )}
                                                        </>
                                                    })
                                                }
                                            </table>
                                        </div>
                                    })
                                    : <></>}
                            </div>
                        </div>
                        <div style={{ width: '23%', }}>
                            {this.state.editHeader || !this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].DOWNLOAD ? <button style={{ position: 'fixed', opacity: '0.5', top: '80px', right: '15px', cursor: "default" }} className="add-user-form-submit-button">
                                Download
                            </button> : <button style={{ position: 'fixed', top: '80px', right: '15px' }} className="add-user-form-submit-button" onClick={window.print}>
                                Download
                            </button>}
                            <div style={{ height: '78vh', overflow: 'auto', position: 'fixed', top: '135px', width: '23%' }}>
                                {this.state.reportData.length == 0 ? <div style={{ width: '100%', textAlign: 'center' }}>No reports section available.</div> : <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "80%" }} />
                                    <col style={{ width: "15%" }} />

                                    {/* <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}></th>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>Algorithm</th>
                                        <th style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>Action</th>
                                    </tr> */}
                                    {this.state.reportData.map((report, key) => {
                                        if (report.statistics) {
                                            return <>
                                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '35px ', fontSize: '14px' }}>
                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>
                                                        <input type='checkbox' style={{ height: '15px', width: '15px', cursor: 'pointer' }}
                                                            checked={report.visibleInReport}
                                                            onClick={() => {
                                                                this.checkedTrue(key)
                                                                // this.state.reportData[key].visibleInReport = false
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px' }}>{report.algorithm_type}</td>
                                                    {this.state.isOwner ? <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 20px', textAlign: 'center' }}>
                                                        <img src={DeleteIcon} style={{ width: '20px', height: '20px', cursor: this.props.location.state.permissions[DRONENAKSHA_FEATURES.REPORTS].DELETE ? 'pointer' : "default", opacity: this.props.location.state.permissions[DRONENAKSHA_FEATURES.REPORTS].DELETE ? "1" : "0.2" }} onClick={() => {
                                                            this.props.location.state.permissions[DRONENAKSHA_FEATURES.REPORTS].DELETE && this.setState((state) => ({
                                                                ...state,
                                                                deleteReport_id: report.report_id,
                                                                itemName: "Customise report item",
                                                                processPopupIcon: "WARNING",
                                                                processAction: "DELETE",
                                                                processMessage: null,
                                                                showProcessPopup: true,
                                                            }))
                                                        }} />
                                                    </td> : <></>}
                                                </tr>
                                            </>
                                        }
                                    })}

                                </table>}
                            </div>
                        </div>
                    </div>
                </div >}
            </>
        );
    }
}

export default aeroFarmPlantHealthReport;