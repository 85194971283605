import React, { Component } from 'react';
import Help from "../../sidebaricon/Help.png";
import Settings from "../../sidebaricon/Settings.png";
import dashboardActive from '../../icons/AeroCapture.svg';
import service from "../../sidebaricon/Services.png";
import AppConstants from '../../AppConstants';
import Images from './Images.js';
import DetailedReport from './TaskDetailedReport';
import SummaryReport from './TaskSummaryReport';
import ImageSlider from './TaskReportImageSlider';
import ScreenDivider from './ReusableComp/ScreenDivider';
import TaskDetails from './ReusableComp/TaskDetails';
import VerticalStepper from '../../ReusableComponents/Stepper/VerticalStepper';
import createTaskDone from '../assets/icons/stepperIcons/createTaskDone.png'
import createTaskActive from '../assets/icons/stepperIcons/createTaskActive.png'
import createTaskInactive from '../assets/icons/stepperIcons/createTaskInactive.png'
import uploadImagesDone from '../assets/icons/stepperIcons/uploadImagesDone.png'
import uploadImagesActive from '../assets/icons/stepperIcons/uploadImagesActive.png'
import uploadImagesInactive from '../assets/icons/stepperIcons/uploadImagesInactive.png'
import reportsDone from '../assets/icons/stepperIcons/reportsDone.png'
import reportsActive from '../assets/icons/stepperIcons/reportsActive.png'
import reportsInactive from '../assets/icons/stepperIcons/reportsInactive.png'
import processDone from '../assets/icons/stepperIcons/processDone.png'
import processActive from '../assets/icons/stepperIcons/processActive.png'
import processInactive from '../assets/icons/stepperIcons/processInactive.png'
import CreateTaskView from './ReusableComp/CreateTaskView';
import SelectImageSet from './ReusableComp/SelectImageSet';
import TaskProcessView from './ReusableComp/TaskProcessView';
import * as API from '../../api.js';
import dateFormat from 'dateformat';
import { generateRandomColor, getLimitedName, getLocalStorageValue, getPicstorkDefaultPlan, getRandomHexColour, isTaskEditable, skipAnnotationTaskTour } from '../../ReusableComponents/reusableFunctions';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import ReactJoyride, { STATUS } from 'react-joyride';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import TourGraphics from '../../assets/Images/tourGraphics.png';
import { ActiveRole, getActiveTeam, getFeaturesPermissions, PICSTORK_FEATURES } from '../../Teams';
import MapAnnotation from './MapAnnotation';

class AnnotationReportTask extends Component {
    constructor() {
        super();
        this.state = {
            classes: [],
            expand: true,
            currentStep: 1,
            joyrideTrigger: false,
            showProcessPopup: false,
            processPopupIcon: null,
            processMessage: null,
            annotated: 0,
            unannotated: 0,
            selectedReport: null,
            triggerCreateTaskTour: false,
            currentlyActiveTour: 0,
            runTour: false,
            showTourPopup: false,
            showTour: false,
            taskImgName: undefined,
            taskNotFoundPopup: true
        };
    }

    isUserHasEditAccess = () => {
        return (this.state.permissions?.[PICSTORK_FEATURES.TASKS].EDIT)
    }

    setStepData = async (onlySetSteps) => {
        let currentStep = onlySetSteps ? this.state.currentStep : this.state.task.state ? this.state.task.state : 1
        let { task, annotationSet, collection } = this.state
        const isEditableTask = this.isUserHasEditAccess()
        this.setState((state) => ({
            ...state,
            currentStep,
            annotationSet: task.annotation_set ? task.annotation_set : annotationSet,
            steps: [
                {
                    stepName: 'Create task',
                    stepNumber: 1,
                    description1: `Task : ${task ? getLimitedName(task.task_name, 18) : "-"}`,
                    description2: `Created on ${task ? dateFormat(task.date_created, "dd mmm yyyy") : "-"}`,
                    completed: task.state > 1,
                    actionButton: "Create Task",
                    enable: task && task.task_id,
                    stepIcon: [createTaskActive, createTaskDone, createTaskInactive],
                    expand: true
                },
                {
                    stepName: 'Images',
                    stepNumber: 2,
                    description1: `Count : ${task ? task.images : '0'}`,
                    description2: `Created on ${collection && collection.created_on ? dateFormat(collection.created_on, 'dd mmm yyyy') : "-"}`,
                    completed: task.state > 2,
                    actionButton: "Setup",
                    enable: isEditableTask && currentStep == 2 && task.images > 0,
                    stepIcon: [uploadImagesActive, uploadImagesDone, uploadImagesInactive],
                    expand: true
                },
                {
                    stepName: 'Setup',
                    stepNumber: 3,
                    description1: `Status : ${task.state > 3 && task ? task.status : '-'}`,
                    description2: `Last updated on ${task.state > 3 && task ? dateFormat(task.status_update_timestamp, "dd mmm yyyy") : '-'}`,
                    completed: task.state > 3,
                    actionButton: "Start",
                    enable: (isEditableTask && currentStep == 3 && task.models && task.models.length > 0) ? true : false,
                    stepIcon: [processActive, processDone, processInactive],
                    expand: true
                },
                {
                    stepName: 'Report',
                    stepNumber: 4,
                    description1: task.status == AppConstants.TASK.STATUS.COMPLETED ? AppConstants.TASK.STATUS.COMPLETED : task.status == AppConstants.TASK.STATUS.IN_PROGRESS ? AppConstants.TASK.STATUS.IN_PROGRESS : '-',
                    description2: '',
                    completed: task.status == AppConstants.TASK.STATUS.COMPLETED || task.status == AppConstants.TASK.STATUS.FAILED,
                    actionButton: null,
                    enable: isEditableTask && task.status == AppConstants.TASK.STATUS.COMPLETED || task.status == AppConstants.TASK.STATUS.FAILED,
                    stepIcon: [reportsActive, reportsDone, reportsInactive],
                    expand: true
                },
            ],
        }))
    }

    getTask = () => {
        API.getAeroCaptureTask(this.state.task.task_id).then(async (data) => {
            this.setState({
                task: data,
                collection: data?.image_collection_id ? { id: data.image_collection_id, collection_name: data.collection_name, created_on: data.imageset_created_on } : undefined
            }, () => this.setStepData(false))
        }).catch((e) => {
            console.log(e);
            this.setState({
                taskNotFoundPopup: {
                    open: true,
                    icon: "ERROR",
                    msg: e?.message ? e.message : 'Something wents wrong while getting task.'
                }
            })
        })
    }

    initData = () => {
        let { user, type, task, collection, project, plan } = this.props.location.state;
        this.setState({
            user,
            type,
            task: task || JSON.parse(localStorage.getItem('created_task')),
            project,
            plan,
            collection,
        }, async () => {
            try {
                const permissions = await getFeaturesPermissions([PICSTORK_FEATURES.TASKS, PICSTORK_FEATURES.IMAGECOLLECTIONS, PICSTORK_FEATURES.ANNOTATIONS, PICSTORK_FEATURES.REPORTS])
                const activeTeam = await getActiveTeam()
                this.setState({ permissions, activeTeam }, () => {
                    if (this.state.task && this.state.task.task_id)
                        this.getTask()
                    else {
                        if (getLocalStorageValue('annotationTaskTour') == null) this.setState((state) => ({
                            ...state,
                            showTourPopup: true
                        }))
                        this.setState((state) => ({
                            ...state,
                            steps: [
                                {
                                    stepName: 'Create task',
                                    stepNumber: 1,
                                    description1: `Task : -`,
                                    description2: `Created on : -`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Create Task",
                                    stepIcon: [createTaskActive, createTaskDone, createTaskInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Images',
                                    stepNumber: 2,
                                    description1: `Count : 0`,
                                    description2: '',
                                    completed: false,
                                    enable: false,
                                    actionButton: "Setup",
                                    stepIcon: [uploadImagesActive, uploadImagesDone, uploadImagesInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Setup',
                                    stepNumber: 3,
                                    description1: `Status : -`,
                                    description2: `Last updated : -`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Start",
                                    stepIcon: [processActive, processDone, processInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Report',
                                    stepNumber: 4,
                                    description1: '-',
                                    description2: '-',
                                    completed: false,
                                    enable: false,
                                    actionButton: null,
                                    stepIcon: [reportsActive, reportsDone, reportsInactive],
                                    expand: true
                                },
                            ],
                        }))
                    }
                })

            }
            catch (e) {
                window.location.replace("/")
            }


        })
    }
    componentDidMount() {
        document.title = "Annotation Report task";
        this.initData()

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.location?.state
            && (this.props.location.state?.task?.task_id
                && (this.props.location.state?.task?.task_id !== this.state?.task?.task_id)))
            window.location.reload()
            

        if (prevProps.location.state.notificationId != this.props.location.state.notificationId) window.location.reload()
    }

    closeActivityPopup = () => {
        this.setState({ showProcessPopup: false })
    }

    createTask = async (step) => {
        let { task, project } = this.state
        const plan = await getPicstorkDefaultPlan(project)
        this.setState({ plan })
        if (task.task_name && task.task_name != "" && project.project_name && project.project_name != "") {
            API.addAnnotationReportTask({
                ...task,
                project_name: project.project_name,
                project_id: project.id,
                plan_id: plan.id,
                plan_name: plan.plan_name,
                status: AppConstants.TASK.STATUS.CREATING,
                type: [],
                state: step + 1
            }).then((createdTask) => {
                localStorage.setItem('created_task', JSON.stringify(createdTask.task))
                this.setState({
                    task: createdTask.task,
                    currentStep: step + 1,
                    selectedImageForAnnotation: undefined,
                    steps: this.state.steps.map((s) => {
                        if (s.stepNumber == step) {
                            s.completed = true
                            s.description1 = `Task : ${task ? getLimitedName(task.task_name, 15) : "-"}`
                            s.description2 = `Created on ${this.state.task ? dateFormat(this.state.task.date_created, "dd mmm yyyy") : "-"}`
                        }
                        return s
                    })
                })
            }).catch((e) => {
                this.setState({
                    showProcessPopup: true,
                    processPopupIcon: 'ERROR',
                    processMessage: e.message,
                    steps: this.state.steps.map((s) => {
                        if (s.stepNumber == step) {
                            s.completed = false
                        }
                        return s
                    })
                })
            })

        } else {
            console.log("Project or plan is missing");
        }
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            if (this.state.currentlyActiveTour == 3) localStorage.setItem('createTaskTour3', 2)
            else if (this.state.currentlyActiveTour == 4) localStorage.setItem('processTour3', 2)
            this.setState({
                runTour: false,
            })
        } else if ([STATUS.SKIPPED].includes(status)) {
            skipAnnotationTaskTour()
            this.setState({
                runTour: false,
            })
        }
    };

    updateTaskDetails = (state) => {
        let { task, collection } = this.state
        let status = state == 4 ? AppConstants.TASK.STATUS.COMPLETED : null
        let classes = null
        if (task.type != null) {
            classes = task.type.map(t => {
                return { "name": t, "color": getRandomHexColour() }
            })
        }
        API.updateTaskDetails(task.task_id, { state, status, classes, collection_id: collection ? collection.id : null, task_purpose: task.task_purpose, linked_map: task.linked_map }).then((updatedTask) => {
            this.setState({ task: updatedTask.task }, () => {
                this.setStepData(false)
            })
        }).catch((e) => {
            console.log("Error : ", e);
        })
    }

    addAnnotationSet = (collection, step) => {
        this.setState({
            itemName: "Task",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: this.state.linkedMap ? "Please wait while we are scanning images..." : "Please wait while we are scanning and resizing your images...",
            showProcessPopup: true,
        })

        API.copyTaskCollection(this.state.task.task_id, this.state.task.image_collection_id).then(result => {
            this.setState({ collection: result.collection, showProcessPopup: false }, () => {
                this.updateTaskDetails(step + 1)
                this.setState({ openStartTaskPopup: step + 1 == 4 }, () => {
                    this.setState({
                        currentStep: step + 1,
                        selectedImageForAnnotation: undefined,
                        steps: this.state.steps.map((s) => {
                            if (s.stepNumber == step)
                                s.completed = true
                            return s
                        })
                    })
                })
            })
        }).catch(e => {
            console.log("error : ", e)
        })
    }
    render() {
        let { user, type } = this.state
        let topBar = <div className="top-bar-text">Annotation Report Task
            <ActiveRole />
        </div>
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardActive,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                    selected: true,
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type
        }
        let leftSide = {
            render:
                <VerticalStepper
                    stepHeight={'100px'}
                    expanded={this.state.expand}
                    taskType={4}
                    steps={this.state.steps}
                    currentStep={this.state.currentStep}
                    onClickStep={(step) => {
                        this.setState({
                            currentStep: step,
                            selectedReport: null,
                            selectedImageForAnnotation: undefined,
                        }, () => this.setStepData(true))
                    }}
                    onClickExpandStep={(step) => {
                        this.setState({
                            steps: this.state.steps.map((s) => {
                                if (s.stepNumber == step)
                                    s.expand = !s.expand
                                return s
                            })
                        })
                    }}
                    setTourComplete={(t) => {
                        if (t === 1) this.setState({ triggerCreateTaskTour: true })
                        else this.setState({ triggerCreateTaskTour: false })
                    }}
                    joyrideTrigger={this.state.showTour ? true : false}
                    onClickActionButton={(step) => {
                        if (step == 1) {
                            this.setState({
                                steps: this.state.steps.map((s) => {
                                    if (s.stepNumber == step)
                                        s.completed=true;
                                    return s
                                })
                            },()=>{
                                this.createTask(step)
                            })
                                // this.createTask(step)
                        } else if (step === 2) {
                            // this.setState({
                            //     itemName: "Task",
                            //     processPopupIcon: "WAIT",
                            //     processAction: "ADD",
                            //     processMessage: "Please wait while we are scanning images...",
                            //     showProcessPopup: true,
                            // })
                            this.setState({
                                task: {
                                    ...this.state.task,
                                    image_collection_id: this.state.collection.id
                                }
                            }, () => {
                                this.addAnnotationSet(this.state.collection, step)

                            })
                        }
                        else {
                            this.updateTaskDetails(step + 1)
                            this.setState({ openStartTaskPopup: step + 1 == 4 }, () => {
                                this.setState({
                                    currentStep: step + 1,
                                    selectedImageForAnnotation: undefined,
                                    steps: this.state.steps.map((s) => {
                                        if (s.stepNumber == step)
                                            s.completed = true
                                        return s
                                    })
                                })
                            })
                        }
                    }}
                />
            ,
            style: {
                width: AppConstants.STEPPER.STEPPERWIDTH,
                padding: "5px 10px",
                // background: '#EAEDF0',
                background: AppConstants.COLORS.STEPPERCOLOR,
                height: '100%',
                overflow: 'auto'
            }
        }

        let rightSide = {
            render:
                <>
                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                    />
                    {/* --------------------------------------------------------------- */}

                    {this.state.currentStep === 1 ?
                        <CreateTaskView
                            activeTeam={this.state.activeTeam}
                            permissions={this.state.permissions}
                            task={this.state.task}
                            startTour={this.state.triggerCreateTaskTour}
                            taskType={4}
                            plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                            project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                            setTourComplete={(t) => {
                                if (t === 1) this.setState({ triggerCreateTaskTour: false })
                            }}
                            user={this.state.user}
                            taskCreationDone={(project, task) => {
                                this.setState({
                                    task,
                                    project,
                                    steps: this.state.steps.map((step => step.stepNumber === 1 ? { ...step, enable: this.isUserHasEditAccess() && task.task_name && project.id } : step)),
                                    tourSteps: [{
                                        disableBeacon: true,
                                        target: '#button-step1',
                                        content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to create task</p></div>),
                                        title: (<div style={AppConstants.TITLESTYLING}>Create Task</div>),
                                        placement: 'right',
                                        styles: {
                                            options: {
                                                width: 250,
                                            },
                                        },
                                    }],
                                    currentlyActiveTour: 3,
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            runTour: task.task_name && project.id && getLocalStorageValue('createTaskTour3') == 1 ? true : false
                                        })
                                    }, AppConstants.TRIGGER_DELAY)
                                })
                            }} /> :
                        //step two
                        this.state.currentStep === 2 ?
                            (this.state.collection && this.state.collection.id ?
                                // if user selected imageset
                                <Images
                                    collectionId={this.state.collection.id}
                                    permissions={this.state.permissions}
                                    data={
                                        {
                                            collection: this.state.collection,
                                            annotationSet: this.state.annotationSet,
                                            plan: this.state.plan || { id: this.state.task && this.state.task.plan_id },
                                            project: this.state.project || { id: this.state.task && this.state.task.project_id }, user: this.state.user,
                                            task: this.state.task
                                        }
                                    }
                                    updateImagesCount={(status, count, linkedMap) => {
                                        let stepData = this.state.steps
                                        stepData[1].description1 = `Count : ${count}`
                                        stepData[1].enable = count != 0
                                        this.setState({
                                            steps: stepData,
                                            linkedMap,
                                            task: { ...this.state.task, linked_map: linkedMap }
                                        })
                                    }}
                                    taskType={4}
                                    setCollection={(collection) => {
                                        if (this.isUserHasEditAccess()) this.setState({ collection: collection });
                                    }
                                    }
                                    updateTaskState={(state) => this.updateTaskDetails(state)}
                                /> :
                                <SelectImageSet
                                    permissions={this.state.permissions}
                                    setCollection={(collection) => {
                                        this.setState({ collection: collection })
                                    }}
                                    plan={this.state.plan}
                                    taskType={4}
                                    data={{
                                        user: this.state.user,
                                        type: this.state.type,
                                        project: this.state.project || { id: this.state.task.project_id },
                                        task: this.state.task,
                                    }}
                                />
                            ) :

                            //step 3
                            this.state.currentStep === 3 ?
                                <TaskProcessView
                                    permissions={this.state.permissions}
                                    classes={this.state.task.type ? this.state.task.type : []}
                                    plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                                    project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                                    collection={this.state.collection}
                                    task={this.state.task}
                                    taskType={4}
                                    onChangeDetails={(data) => {
                                        if (this.isUserHasEditAccess() && isTaskEditable(this.state.task.status)) {
                                            let task_data = this.state.task
                                            task_data.type = data.type && data.type.length > 0 ? data.type : []
                                            let steps_data = this.state.steps
                                            steps_data[2].enable = (data.type && data.type.length > 0)
                                            this.setState({
                                                task: task_data,
                                                steps: steps_data,
                                                tourSteps: [{
                                                    disableBeacon: true,
                                                    target: '#button-step3',
                                                    content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to start task</p></div>),
                                                    title: (<div style={AppConstants.TITLESTYLING}>Start Task</div>),
                                                    placement: 'right',
                                                    styles: {
                                                        options: {
                                                            width: 250,
                                                        },
                                                    },
                                                }],
                                                currentlyActiveTour: 4
                                            }, () => {
                                                setTimeout(() => {
                                                    this.setState({
                                                        runTour: data.type.length > 0 && getLocalStorageValue('processTour3') == 1 ? true : false
                                                    })
                                                }, AppConstants.TRIGGER_DELAY)
                                            })
                                        }
                                    }
                                    }
                                /> :

                                //step 
                                this.state.currentStep === 4 ?
                                    !this.state.selectedReport ?
                                        <TaskDetails
                                            permissions={this.state.permissions}
                                            user={this.state.user}
                                            task={this.state.task}
                                            classes={this.state.classes}
                                            changeView={(type) => {
                                                this.setState({ selectedReport: type == 1 ? 'summary' : 'detailed' })
                                            }}
                                            taskImageType={(name) => { this.setState({ taskImgName: name }) }}
                                        /> :
                                        (this.state.task.linked_map && !(this.state.selectedReport == 'summary') ?
                                            <MapAnnotation
                                                permissions={this.state.permissions}
                                                showAnnotationTool
                                                selectedImageForAnnotation={{
                                                    altitude: null,
                                                    annotation_status: false,
                                                    collection_id: this.state.collection.id,
                                                    created_on: this.state.task.date_created,
                                                    file_name: this.state.taskImgName,
                                                    validTiff: true
                                                }}
                                                selectedReportType={this.state.selectedReport}
                                                user={this.state.user}
                                                annotationSetId={this.state.annotationSet?.id}
                                                task={{ ...this.state.task, project_name: this.state.project.project_name, status: "Creating" }}
                                                updateTask={(task) => {
                                                    this.setState({
                                                        task,
                                                        // steps: this.state.steps.map((step => step.stepNumber === 3 ? { ...step, completed: false } : step)),
                                                    })

                                                }}
                                                setAnnotationDone={(type) => {
                                                    this.setState({ selectedReport: type == 1 ? null : "summary" })
                                                }}
                                            /> :
                                            this.state.selectedReport == 'summary' ?
                                                <SummaryReport
                                                    permissions={this.state.permissions}
                                                    user={this.state.user}
                                                    type={this.state.type}
                                                    task={this.state.task}
                                                    plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                                                    project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                                                    changeView={(type) => {
                                                        this.setState({ selectedReport: type == 1 ? null : 'detailed' })
                                                    }}
                                                /> :
                                                this.state.selectedReport == 'detailed' ?
                                                    <DetailedReport
                                                        permissions={this.state.permissions}
                                                        user={this.state.user}
                                                        type={this.state.type}
                                                        task={this.state.task}
                                                        plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                                                        project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                                                        changeView={(type, data) => {
                                                            this.setState({ imageSliderData: data, selectedReport: type == 1 ? null : type == 2 ? 'summary' : 'slider' })
                                                        }}
                                                    />
                                                    :
                                                    <ImageSlider
                                                        permissions={this.state.permissions}
                                                        data={this.state.imageSliderData}
                                                        onClassListUpdated={(classes) => {
                                                            this.setState({
                                                                task: { ...this.state.task, type: classes }
                                                            })
                                                        }}
                                                        changeView={(type) => {
                                                            this.setState({ selectedReport: type == 1 ? null : 'detailed' })
                                                        }}
                                                    />) :
                                    <></>
                    }
                </>
        }
        return (<>
            {this.state.user ?
                <ScreenDivider
                    sidebarConfigurations={sidebarConfigurations}
                    leftSide={leftSide}
                    rightSide={rightSide}
                    topBar={topBar}
                    steps={this.state.steps}
                /> :
                <></>
            }

            {/* Activity popup */}
            <ActivityPopup
                height="130px"
                open={this.state.taskNotFoundPopup?.open}
                icon={this.state.taskNotFoundPopup?.icon}
                msg={this.state.taskNotFoundPopup?.msg}
                close={() => { window.history.back(); }}
            />
            {/* End activity popup */}

            {/* -------------- Tour confirmation popup ----------------------- */}

            <AnimatedModal
                height="360px"
                width="500px"
                zIndex={5}
                onRequestClose={this.closeTaskPopup}
                isOpen={this.state.showTourPopup}
            >
                <div className="content-text" style={{ padding: '30px 30px 10px 30px' }}>
                    <div className="services-content-title" style={{ fontSize: "20px", textAlign: 'left' }}>
                        Getting started with Annotation
                    </div>
                    <div style={{ textAlign: 'left', fontSize: '12px', marginTop: '10px' }}>
                        We would like to guide you through the process, would you like to take a tour ?

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', height: "190px", alignItems: 'center' }}>
                        <img style={{ height: '180px' }} src={TourGraphics} />
                    </div>

                    <div style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'end' }}>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-right btn-ok"
                            onClick={() => {
                                this.setState({
                                    showTourPopup: false,
                                    showTour: true,
                                    joyrideTrigger: true,
                                })
                            }}
                        >
                            Yes
                        </button>
                        <button style={{ marginLeft: "20px", padding: '8px 30px 7px 30px' }}
                            className="add-user-form-submit-button"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showTourPopup: false,
                                    showTour: false
                                }), () => {
                                    skipAnnotationTaskTour()
                                });
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>

            </AnimatedModal>
            {/* --------------------------------------------------------------- */}

            <ReactJoyride
                continuous={true}
                run={this.state.runTour}
                hideBackButton={true}
                hideCloseButton={true}
                showSkipButton={true}
                steps={this.state.tourSteps}
                disableOverlayClose={true}
                floaterProps={AppConstants.FLOATER_PROPS}
                locale={AppConstants.LOCALE_BUTTONS}
                callback={(e) => this.handleJoyrideCallback(e)}
                styles={{
                    buttonNext: AppConstants.NEXT_BUTTON,
                    buttonSkip: AppConstants.SKIP_BUTTON,
                    spotlight: AppConstants.SPOTLIGHT,
                    options: AppConstants.MAIN_OPTIONS
                }}
            />
        </>

        );
    }
}

export default AnnotationReportTask

