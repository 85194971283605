import React, { useEffect, useState } from 'react';
import SideBar from '../SideBar';
import AssignTask from './AssignTask';
import PlannerTaskDetails from "./PlannerTaskDetails.js"
import DropDownWithButton from "../ReusableComponents/DropDownWithButton"
import ButtonWithIcon from "../ReusableComponents/ButtonWithIcon"
import ADD from "../icons/addBlue.png"
import EDIT from "../icons/editPlanner.png"
import DELETE from "../icons/delete.png"
import RIGHT from "../icons/scrollRight.png"
import EMPTYLIST from "../icons/empty-box.png"
// import EMPTYLIST from "../icons/emptylist.png"

import ServiceInActive from "../sidebaricon/Services.png"
import TeamsInActive from "../sidebaricon/TeamsInactive.png"
import Calender from "../sidebaricon/calenderActive.png"
import Help from "../sidebaricon/Help.png"
import Settings from "../sidebaricon/Settings.png"
import { setMonth } from 'date-fns';
import { getAssignedTasksAPI } from '../api';
import { Link } from 'react-router-dom';
import CalenderTile from './CalenderTile';
import AppConstants from '../AppConstants';
import { getActiveTeamId } from '.';
import LoaderComponent from '../ReusableComponents/LoaderComponent.js';

const Planner = (props) => {
  const user = props.location.state.user

  const [assignedTasks, setAssignedTasks] = useState([])
  const [taskAssigner, setTaskAssigner] = useState(false)
  const [activeTaskDetails, setActiveTaskDetails] = useState(false)
  const months = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const [activeTasks, setActiveTasks] = useState([])
  const [newNotificationDate, setNewNotificationDate] = useState(undefined);
  const [currYear, setCurrYear] = useState(newNotificationDate ? new Date(newNotificationDate).getFullYear() : new Date().getFullYear())
  const [currMonth, setCurrMonth] = useState(newNotificationDate ? new Date(newNotificationDate).getMonth() : new Date().getMonth())
  const [currDay, setCurrDay] = useState(newNotificationDate ? new Date(newNotificationDate).getDate() : new Date().getDate())
  const [activeDay, setActiveDay] = useState({ currDay, currMonth, currYear })
  const [loader, setLoader] = useState(true)

  const [editTask, setEditTask] = useState(false)

  const checkIfDateOld = () => {
    //this function will return true if date is old
    const currDate = new Date()
    if (activeDay.currYear > currDate.getFullYear()) return false
    if (activeDay.currYear < currDate.getFullYear()) {
      return true;
    }
    if (activeDay.currYear === currDate.getFullYear()) {
      if (activeDay.currMonth < currDate.getMonth()) {
        return true;
      }
      if (activeDay.currMonth > currDate.getMonth()) {
        return false;
      }
      if (activeDay.currMonth === currDate.getMonth()) {
        if (activeDay.currDay < currDate.getDate()) {
          return true;
        }
      }

    }
    return false;
  }

  const getAssignedTasks = async () => {
    setLoader(true)
    getAssignedTasksAPI(await getActiveTeamId(), props.atProjectLevel ? props.selectedProject.id : false).then(data => {
      setAssignedTasks(data?.assignedTasks || [])
      setActiveTasks((data?.assignedTasks || []).filter(t => {
        const taskDate = new Date(t.due_date)
        const taskDay = taskDate.getDate();
        const taskMonth = taskDate.getMonth();
        const taskYear = taskDate.getFullYear();
        return taskDay == activeDay.currDay && taskMonth == activeDay.currMonth && taskYear == activeDay.currYear
      }))
      setLoader(false)
    })
  }
  function leapyear(year) {
    return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
  }
  const calculateNoOfDays = (monthNo) => {
    if (monthNo == 1) {
      if (leapyear(currYear)) return 29 - 7 + vacantDays()
      else return 28 - 7 + vacantDays()
    }
    if (monthNo % 2 == 0)
      return 30 - 7 + vacantDays()
    else
      return 31 - 7 + vacantDays()
  }
  const handleToday = () => {

    setCurrMonth(new Date().getMonth())
    setCurrDay(new Date().getDate())
    setCurrYear(new Date().getFullYear())
    setActiveDay({ currDay: new Date().getDate(), currMonth: new Date().getMonth(), currYear: new Date().getFullYear() })
  }
  function formatAMPM(dateTimeString) {
    const dateTime = new Date(dateTimeString);

    // Get the hours, minutes, and seconds from the Date object
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();

    // Convert the hours to the 12-hour format
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12;

    // Determine the AM/PM indicator based on the hours value
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Return the time in the 12-hour format with the AM/PM indicator
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${amPm}`;

  }


  const handleActiveDay = (day) => {
    setActiveDay({ currDay: day, currMonth, currYear })
  }


  const handleTaskAssigner = () => {
    setTaskAssigner(true)
  }


  const handleActiveTaskDetails = (task) => {
    setActiveTaskDetails(task)
  }


  const onClickEditTask = (e, task) => {
    e.stopPropagation();
    if (task.hasEditAccess) {
      setEditTask(task)
    }
  }


  const onClickLeft = () => {
    if (currMonth <= 0) {
      setCurrMonth(11)
      setCurrYear(prev => prev - 1)
    } else {
      setCurrMonth(prev => prev - 1)
    }
  }


  const onClickRight = () => {
    if (currMonth >= 11) {
      setCurrMonth(0)
      setCurrYear(prev => prev + 1)
    } else {
      setCurrMonth(prev => prev + 1)
    }
  }


  const vacantDays = () => {
    return new Date(currYear, currMonth, 1).getDay();
  }
  const [daysAfterFirstRow, setDaysAfterFirstRow] = useState(8 - vacantDays())

  const getWeekDay = (day) => {
    return weekDays[new Date(currYear, currMonth, day).getDay()];
  }

  useEffect(() => {
    getAssignedTasks()

    if (newNotificationDate) {
      const newDay = new Date(props.location?.state?.assignedTaskDate).getDate();
      const newMonth = new Date(props.location?.state?.assignedTaskDate).getMonth();
      const newYear = new Date(props.location?.state?.assignedTaskDate).getFullYear();
      setCurrMonth(newMonth)
      setCurrYear(newYear)
      setActiveDay({ currDay: newDay, currMonth: newMonth, currYear: newYear })
    }
  }, [newNotificationDate, props.location.state.notificationTempId])

  useEffect(() => {
    setNewNotificationDate(props.location?.state?.assignedTaskDate)
  }, [props.location.state.notificationTempId])

  useEffect(() => {
    setActiveTasks(assignedTasks.filter(t => {
      const taskDate = new Date(t.due_date)
      const taskDay = taskDate.getDate();
      const taskMonth = taskDate.getMonth();
      const taskYear = taskDate.getFullYear();
      return taskDay == activeDay.currDay && taskMonth == activeDay.currMonth && taskYear == activeDay.currYear
    }))
  }, [activeDay, assignedTasks])

  useEffect(() => {
    if (currMonth === new Date().getMonth() && currYear === new Date().getFullYear() && !newNotificationDate)
      setActiveDay({ currDay: new Date().getDate(), currMonth, currYear })
    setDaysAfterFirstRow(8 - vacantDays())
  }, [currYear, currMonth])


  // common styling
  const emptyListStyle = { width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center", opacity: "0.4", filter: "grayscale(1)" }



  let sidebarConfigurations = {
    options: [
      {
        icon: ServiceInActive,
        text: "Services",
        path: "/",

      },
      {
        icon: TeamsInActive,
        text: "Teams",
        path: "/teams",
      },
      {
        icon: Calender,
        text: "Planner",
        path: "/planner",
        selected: true,
      },
      {
        icon: Settings,
        text: "Settings",
        path: "/profile-settings",
      },
      {
        icon: Help,
        text: "Help",
        path: "/feedback",
      },

      // {
      //       icon: null,
      //       text: "hr",
      //       path: null
      //   },
    ],
    user
  };

  // service level Planner--------------------------------------------------------
  const serviceLevel = <>{user.username ? <div className="wrapper" >
    <SideBar config={sidebarConfigurations} height={"initial"} />
    {taskAssigner || editTask ? <AssignTask
      service={props.service}
      setAssignedTasks={setAssignedTasks}
      assignedTask={editTask}
      selectedProject={props.selectedProject}
      date={new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay).getDate() <= new Date().getDate() && new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay).getMonth() <= new Date().getMonth() && new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay).getFullYear() <= new Date().getFullYear() ? new Date() : new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay)}
      closeTaskAssigner={() => {
        setTaskAssigner(false)
        setEditTask(false)
      }}
    /> : <></>}
    {activeTaskDetails ? <PlannerTaskDetails
      setAssignedTasks={setAssignedTasks}
      assignedTask={editTask}
      formatAMPM={formatAMPM}
      task={activeTaskDetails}
      closeTaskDetails={() => setActiveTaskDetails(false)}
      user={user}
    /> : <></>}
    <div className="right-content" style={{ paddingLeft: "6px", height: "100%", display: "flex", flexDirection: "column" }}>
      {loader ? <LoaderComponent />
        : <>
          {!props.atProjectLevel && <div className="top-bar" style={{ zIndex: "4", padding: "10px", display: "flex", alignItems: "center" }}>
            <div className="top-bar-text" style={{ top: "0px", left: "0px" }}>{"Task Planner"}</div>
          </div>}
          <div style={{ height: "calc(100% - 46px)", display: "flex", flexDirection: "column" }}>
            {/*this is top bar*/}
            <div style={{ backgroundColor: "white", zIndex: "2", display: "flex", alignItems: "center", padding: "7.5px 20px", color: "#2989cf", }}>
              <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "70%", width: "100%" }}>

                <div style={{ display: "flex", alignItems: "center" }}>

                  <div style={{ width: "15px", height: "15px", transform: "rotate(180deg)", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", marginRight: "10px" }} onClick={() => {
                    setNewNotificationDate(undefined)
                    onClickLeft();
                  }}>
                    <img style={{ width: "100%", height: "100%" }} src={RIGHT} />
                  </div>
                  <div style={{ fontSize: "15px", color: "#3c3c3c" }}>{`${months[currMonth]}, ${currYear}`}</div>
                  <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer", marginLeft: "9px" }} onClick={() => {
                    setNewNotificationDate(undefined)
                    onClickRight()

                  }}>
                    <img style={{ width: "100%", height: "100%" }} src={RIGHT} />
                  </div>
                </div>
                <div style={{ fontWeight: "500", fontSize: "15px", color: "#3c3c3c", marginRight: "61px", display: "flex", alignItems: "center" }}>{`${new Date().getDate()} ${months[new Date().getMonth()]}`}</div>
                <div style={{ zIndex: "2" }}><ButtonWithIcon text="Today" fontSize="11px" onClick={handleToday} /></div>
              </div>
            </div>
            <div style={{ height: "calc(100% - 40px)", display: "flex", padding: "10px", backgroundColor: "rgb(60 60 60 / 0%)", gap: "10px" }}>
              {/*this is left part calender*/}

              <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(7,1fr)", height: "100%", gridAutoRows: "1fr", gap: "1px", backgroundColor: "white", position: "relative" }}>
                {[...Array(vacantDays())].map((e, i) => <CalenderTile weekDay={weekDays[i]} vacant />)}
                {
                  [...Array(7 - vacantDays())].map((e, i) => <CalenderTile weekDay={getWeekDay(i + 1)} currDay={currDay} displayDay={i + 1 < 10 ? "0" + (i + 1) : i + 1} onClick={() => handleActiveDay(i + 1)} activeDay={activeDay} dayNo={i + 1} currMonth={currMonth} tasks={assignedTasks} currYear={currYear} />)
                }
                {
                  [...Array(calculateNoOfDays(currMonth))].map((e, i) => <CalenderTile currDay={currDay} displayDay={i + daysAfterFirstRow < 10 ? "0" + (i + daysAfterFirstRow) : i + daysAfterFirstRow} onClick={() => handleActiveDay(i + daysAfterFirstRow)} activeDay={activeDay} dayNo={i + daysAfterFirstRow} currMonth={currMonth} tasks={assignedTasks} currYear={currYear} />)
                }
              </div>
              {/*this is right part tasks and task details*/}
              <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "43%", backgroundColor: "", gap: "10px", }}>
                {/*this is tasks*/}
                <div style={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: "", maxHeight: "" }}>
                  {/*task top bar*/}
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgb(78 78 78 / 11%)", borderRadius: "5px 5px 0px 0px", fontSize: "15px", padding: "7.5px 15px", color: "#3c3c3c" }}>
                    <span style={{ fontWeight: "500" }}>Tasks</span>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                      {/* <img style={{ width: "100%", height: "100%", cursor: "pointer", transform: "rotate(45deg)" }} src={ADD} /> */}
                      <ButtonWithIcon text={"Add"} icon={ADD} fontSize={"11px"} isDisabled={checkIfDateOld()} onClick={handleTaskAssigner} />
                    </div>
                  </div>
                  {/*task description*/}
                  <div style={{ height: "100%", overflow: "scroll", maxHeight: "", backgroundColor: "white" }}>


                    {
                      activeTasks.length > 0 ? activeTasks?.sort((a, b) => new Date(b.due_date) - new Date(a.due_date)).map((task, i) => <div style={{ display: "flex", backgroundColor: "" }} className="hoverPlannerTask" onClick={
                        () => handleActiveTaskDetails(task)
                      }>

                        <div style={{ backgroundColor: task.status === "Completed" ? AppConstants.PLANNER.STATUS.COMPLETED : task.status === "In Progress" ? AppConstants.PLANNER.STATUS.IN_PROGRESS : task.status === "To do" ? AppConstants.PLANNER.STATUS.TO_DO : "", width: "3px" }}></div>
                        <div style={{ padding: "7px", borderBottom: "rgb(218, 220, 224) solid 1px", width: "100%", }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", fontSize: "15px" }}>
                            <div style={{ maxWidth: "282px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >{task.description}</div>
                            <div style={{ width: "20px", height: "20px", display: "flex", alignItems: "center", cursor: "pointer", opacity: task.hasEditAccess ? "1" : "0.3" }} onClick={(e) => {
                              onClickEditTask(e, task)
                            }}>
                              <img style={{ width: "100%", height: "100%" }} src={EDIT} />
                            </div>
                          </div>
                          <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(147px, 1fr))", fontSize: "12px", color: "#0000009e" }}>
                            <div style={{ display: "flex", alignItems: "center", minWidth: "221px", width: "100%" }}><div style={{ marginRight: "5px" }}>Assigned To</div><div style={{ display: "inline-block", fontSize: "14px", fontWeight: "400", color: "black", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>{task?.assignedTo?.displayName}</div></div>
                            <div style={{ display: "flex", alignItems: "center", minWidth: "112px", justifyContent: "right", width: "100%" }}><div style={{ marginRight: "5px" }}>Priority</div><div style={{ fontSize: "14px", fontWeight: "400", color: "black", width: "58px" }}>{task?.priority}</div></div>
                            <div className="planner-time" style={{ width: "100%", display: "flex", alignItems: "center" }}><div style={{ width: "100%" }}>{formatAMPM(new Date(task?.due_date))}</div></div>
                          </div>
                        </div>
                      </div>) : <div style={emptyListStyle}>
                        <img style={{ width: "25%" }} src={EMPTYLIST} />
                      </div>
                    }

                  </div>
                </div>
                {/*this is task details*/}

              </div>
            </div>
          </div>
        </>
      }
    </div>
  </div> : <div />}</>

  // project level planner------------------------------------------------------------------
  const projectLevel = <>
    {user.username ?
      <>

        {taskAssigner || editTask ? <AssignTask
          service={props.service}
          setAssignedTasks={setAssignedTasks}
          assignedTask={editTask}
          selectedProject={props.selectedProject}
          date={new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay).getDate() <= new Date().getDate() && new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay).getMonth() <= new Date().getMonth() && new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay).getFullYear() <= new Date().getFullYear() ? new Date() : new Date(activeDay.currYear, activeDay.currMonth, activeDay.currDay)}
          closeTaskAssigner={() => {
            setTaskAssigner(false)
            setEditTask(false)
          }}
        /> : <></>}
        {activeTaskDetails ? <PlannerTaskDetails
          setAssignedTasks={setAssignedTasks}
          assignedTask={editTask}
          formatAMPM={formatAMPM}
          task={activeTaskDetails}
          closeTaskDetails={() => setActiveTaskDetails(false)}
          user={user}
        /> : <></>}

        <div style={{ height: "95%", display: "flex", flexDirection: "column", width: "60%", marginTop: "", border: "1px solid rgb(194, 194, 194)", borderRadius: "5px", overflow: "hidden" }}>
          {/*this is top bar*/}
          <div style={{ backgroundColor: "white", zIndex: "2", display: "flex", alignItems: "center", padding: "7.5px 10px 7.5px 20px", color: "#2989cf", borderBottom: "rgb(218, 220, 224) solid 1px", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>

              <div style={{ width: "15px", height: "15px", transform: "rotate(180deg)", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", marginRight: "10px" }} onClick={() => {
                onClickLeft()
              }}><img style={{ width: "100%", height: "100%" }} src={RIGHT} /></div>
              <div style={{ fontSize: "15px", color: "#3c3c3c" }}>{`${months[currMonth]}, ${currYear}`}</div>
              <div style={{ width: "15px", height: "15px", display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer", marginLeft: "9px" }} onClick={() => {
                onClickRight()

              }}><img style={{ width: "100%", height: "100%" }} src={RIGHT} /></div>
            </div>
            <div style={{ fontWeight: "500", fontSize: "15px", color: "#3c3c3c" }}>{`${new Date().getDate()} ${months[new Date().getMonth()]}`}</div>
            {/* <div style={{ marginLeft: "auto" }}><DropDownWithButton textColor="" selectedItem={dateType} onChange={(e) => setDateType(e.target.value)} list={list} fontSize="20px" /></div> */}
            <div style={{ marginLeft: "", zIndex: "2" }}><ButtonWithIcon text="Today" fontSize="11px" onClick={handleToday} /></div>
          </div>
          <div style={{ height: "calc(100% - 42px)", display: "flex", flexDirection: "column", padding: "", backgroundColor: "rgb(60 60 60 / 0%)", gap: "0px" }}>
            {/*this is left part calender*/}

            <div style={{ width: "100%", minWidth: "50%", display: "grid", gridTemplateColumns: "repeat(7,1fr)", height: "50%", gridAutoRows: "1fr", gap: "1px", backgroundColor: "white", position: "relative" }}>
              {[...Array(vacantDays())].map((e, i) => <CalenderTile weekDay={weekDays[i]} vacant atProjectLevel={props.atProjectLevel} />)}
              {
                [...Array(7 - vacantDays())].map((e, i) => <CalenderTile weekDay={getWeekDay(i + 1)} currDay={currDay} displayDay={i + 1 < 10 ? "0" + (i + 1) : i + 1} onClick={() => handleActiveDay(i + 1)} activeDay={activeDay} dayNo={i + 1} currMonth={currMonth} tasks={assignedTasks} currYear={currYear} atProjectLevel={props.atProjectLevel} />)
              }
              {
                [...Array(calculateNoOfDays(currMonth))].map((e, i) => <CalenderTile currDay={currDay} displayDay={i + daysAfterFirstRow < 10 ? "0" + (i + daysAfterFirstRow) : i + daysAfterFirstRow} onClick={() => handleActiveDay(i + daysAfterFirstRow)} activeDay={activeDay} dayNo={i + daysAfterFirstRow} currMonth={currMonth} tasks={assignedTasks} currYear={currYear} atProjectLevel={props.atProjectLevel} />)
              }
            </div>
            {/*this is right part tasks and task details*/}
            <div style={{ display: "flex", flexDirection: "column", height: "50%", minHeight: "50%", width: "100%", backgroundColor: "", gap: "10px", }}>
              {/*this is tasks*/}
              <div style={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: "", maxHeight: "" }}>
                {/*task top bar*/}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgb(78 78 78 / 11%)", borderRadius: "", fontSize: "15px", padding: "7.5px 10px 7.5px 20px", color: "#3c3c3c" }}>
                  <span style={{ fontWeight: "500" }}>Tasks</span>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <ButtonWithIcon text={"Add"} icon={ADD} fontSize={"11px"} isDisabled={checkIfDateOld() || !(props.hasTeamsAccess)} onClick={handleTaskAssigner} /></div>
                </div>
                {/*task description*/}
                <div style={{ height: "100%", overflow: "scroll", maxHeight: "", backgroundColor: "white" }}>


                  {
                    activeTasks.length > 0 ? activeTasks?.sort((a, b) => new Date(b.due_date) - new Date(a.due_date)).map((task, i) => <div style={{ display: "flex", backgroundColor: "" }} className="hoverPlannerTask" onClick={
                      () => handleActiveTaskDetails(task)
                    }>

                      <div style={{ backgroundColor: task.status === "Completed" ? AppConstants.PLANNER.STATUS.COMPLETED : task.status === "In Progress" ? AppConstants.PLANNER.STATUS.IN_PROGRESS : task.status === "To do" ? AppConstants.PLANNER.STATUS.TO_DO : "", width: "3px" }}></div>
                      <div style={{ padding: "7px", borderBottom: "rgb(218, 220, 224) solid 1px", width: "100%", }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", fontSize: "15px" }}>
                          <div style={{ maxWidth: "235px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >{task.description}</div>
                          <div style={{ width: "20px", height: "20px", display: "flex", alignItems: "center", cursor: "pointer", opacity: task.hasEditAccess ? "1" : "0.3" }} onClick={(e) => {
                            onClickEditTask(e, task)
                          }}>
                            <img style={{ width: "100%", height: "100%" }} src={EDIT} />
                          </div>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(134px, 1fr))", fontSize: "12px", color: "#0000009e" }}>
                          <div style={{ display: "flex", alignItems: "center", minWidth: "221px", width: "100%", }}><div style={{ marginRight: "5px" }}>Assigned To </div><div style={{ display: "inline-block", fontSize: "14px", fontWeight: "400", color: "black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }} className='project-level-planner-name'>{task?.assignedTo?.displayName}</div></div>
                          <div style={{ display: "flex", alignItems: "center", minWidth: "112px", justifyContent: "right", width: "100%" }}><div style={{ marginRight: "5px" }}>Priority  </div><div style={{ fontSize: "14px", fontWeight: "400", color: "black", width: "58px" }}>{task?.priority}</div></div>
                          <div className="planner-time" style={{ width: "100%", display: "flex", alignItems: "center", textAlign: "right" }}><div style={{ width: "100%" }}>{formatAMPM(new Date(task?.due_date))}</div></div>
                        </div>
                      </div>
                    </div>) : <div style={emptyListStyle}>
                      <img style={{ width: "20%" }} src={EMPTYLIST} />
                    </div>
                  }

                </div>
              </div>
              {/*this is task details*/}

            </div>
          </div>
        </div>
      </> : <div />}
  </>
  return (
    <>
      {
        props.atProjectLevel ? projectLevel : serviceLevel
      }
    </>
  );
}

export default Planner;
