import React, { useEffect, useState } from 'react'
import nextIcon from "../assets/Icons/next1.png"
import prevIcon from "../assets/Icons/back.png"
import { BLOB_URL } from '../AppConstants'

const ReusableImageSlider = (props) => {
    const scrollINtoView = () => {
        const targetImage = document.getElementById("selected-img")
        if (targetImage)
            targetImage.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest', // Scroll the element to the nearest edge
                inline: 'center', // Center the element horizontally in the viewport
                // inlineFragment: 20, // Add padding to the horizontal scroll position
            })
    }
    useEffect(() => {
        if (props.selectedImageIndex) {
            scrollINtoView()
        }
    }, [props.selectedImageIndex])
    return (
        <div className="slide" style={{ position: "relative", bottom: '100px', width: '100%', height: '100px', zIndex: '10' }}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px 10px',
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    display: "flex",
                    zIndex: 2,
                    backgroundColor: 'rgba(0,0,0,0.6)'
                }}>
                {props.selectedImageIndex == 0 ? <div style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                }} /> : <div style={{ cursor: 'pointer', margin: 'auto' }} onClick={props.onClickPrev}>
                    <img src={prevIcon} style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "5px",
                        color: "white"
                    }} />
                </div>}
                <div id='container' style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: "5px",
                    padding: '10px 20px',
                    overflow: 'auto',
                    gap: '20px',
                    height: '100%',
                }}>
                    {props.imageList?.length > 0 && props.imageList.map((image, idx) => {
                        if (props.selectedImageIndex == idx) scrollINtoView()
                        return <div>
                            {props.selectedImageIndex == idx ? <img
                                id="selected-img"
                                src={`${BLOB_URL}/${props.permissions?.container}/FlightImages/collection-${props.requiredData[0]}/thumb-${image?.file_name || props.imageList[idx]?.image}?${props.permissions?.st}`}
                                style={{
                                    width: "100px",
                                    height: '70px',
                                    transition: 'transform .2s',
                                    transform: 'scale(1.2)',
                                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                    zIndex: '111',
                                    border: 'solid 2px #2989CF',
                                    cursor: "pointer"
                                }}
                            // onClick={() => props.onClickImage(image, idx)}
                            /> :
                                <img
                                    src={`${BLOB_URL}/${props.permissions?.container}/FlightImages/collection-${props.requiredData[0]}/thumb-${image?.file_name || props.imageList[idx]?.image}?${props.permissions?.st}`} style={{
                                        width: "100px",
                                        height: '70px',
                                        cursor: "pointer",
                                        border: 'solid 1px white',
                                    }}
                                    onClick={() => props.onClickImage(image, idx)}
                                />
                            }
                        </div>
                    })

                    }
                </div>
                {props.selectedImageIndex == props.imageList.length - 1 ? <div style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                }} /> : <div style={{ cursor: 'pointer', margin: 'auto' }} onClick={props.onClickNext}>
                    <img src={nextIcon} style={{
                        width: "25px",
                        height: "25px",
                        marginLeft: "5px",
                        color: "white"

                    }} />
                </div>}
            </div>
        </div>
    )
}

export default ReusableImageSlider