import React, { useEffect, useState } from 'react';
import SingleMap from "../../../ReusableComponents/map"
import L from "leaflet"
import AppConstants from '../../../AppConstants';
import { getTilesBounds } from '../../../api';

const MapDetections = ({ onLoaded, regions, imageName, collectionId, permissions }) => {

    const mapInitialized = (map) => {
        getTilesBounds({
            taskId: collectionId,
            tiffType: imageName,
            sas: encodeURIComponent(permissions?.st),
            blobContainer: permissions?.container
        }).then((mapInfo) => {
            setMapCenter(mapInfo, map)
            loadOrtho(mapInfo, map)
            drawBoundsBorder(map, mapInfo.bounds)
            drawRegions(map)
        })
    }

    const drawRegions = (map) => {
        regions.map((region, i) => {
            console.log(region)
            const poly = new L.Polygon(region.poly, {
                name: region.id,
                // color: region.color,
                // fillColor: region.color,
                color: '#0000FF',
                fillColor: '#0000FF',
                fillOpacity: 0.1,
                weight: 3,
            }).addTo(map)
            let p = new L.Tooltip({ className: "index-popup", permanent: true }).setContent(`${region.index}`)
            poly.bindTooltip(p)
        })
    }
    const drawBoundsBorder = async (map, bounds) => {
        const drawingBounds = [[bounds[1], bounds[0]], [bounds[3], bounds[2]]]
        // const orthoBackground = L.rectangle(drawingBounds, { color: '#3C3C3C', weight: 1, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const boundsLayer = L.rectangle(drawingBounds, { color: 'red', weight: 1, fillColor: "none", interactive: false }) // for blink
    }

    const loadOrtho = (mapInfo, map) => {
        let bounds = [[mapInfo.bounds[1], mapInfo.bounds[0]], [mapInfo.bounds[3], mapInfo.bounds[2]]]
        let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${collectionId}/${imageName}/{z}/{x}/{y}.png?sas=${encodeURIComponent(permissions?.st)}&blobContainer=${permissions?.container}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
        }).addTo(map);
        orthoLayer.on("load", async () => {
            onLoaded()
        });

    }

    const setMapCenter = async (info, map1) => {
        let extendBounds = 0.00010
        if (info && map1) {
            let bounds = [[info.bounds[1], info.bounds[0]], [info.bounds[3], info.bounds[2]]]
            // let bounds = [[info.bounds[1] - extendBounds, info.bounds[0] - extendBounds], [info.bounds[3] + extendBounds, info.bounds[2] + extendBounds]]
            map1.fitBounds(bounds);
        }
    }

    return (
        <SingleMap
            // setBaseLayerToState={this.props.setBaseLayerToState}
            // initCenter={this.props.initCenter}
            initBaseLayerURL={""}
            initZoom={18}
            // bounds={this.getCurrentMapExtent()}
            // handleMapClick={this.props.handleMapClick}
            // handleMoveStart={this.props.handleMoveStart}
            maxZoom={28}
            setMap={(map) => {
                mapInitialized(map)
            }}
        >
        </SingleMap>
    );
};

export default MapDetections;
