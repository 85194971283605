import React, { useEffect, useState } from 'react';
import * as API from "../../../api.js";
import MapAnnotation from '../MapAnnotation';
import L from "leaflet";
import "leaflet.pattern"
import { POLYGON, BOX, CIRCLE } from "../../Components/MapAnnotation/Constants"
import LoaderComponent from '../../../ReusableComponents/LoaderComponent.js';


const TrainingDetectionResult = (props) => {
    const [image, setImage] = useState();
    const [map, setMap] = useState();
    const [regionDrawData, setRegionDrawData] = useState()
    const [areaDrawData, setAreaDrawData] = useState()
    const [detectedRegionData, setDetectedRegionData] = useState()
    const mapInitialized = (map) => {
        if (regionDrawData) drawRegions(regionDrawData, map)
        if (areaDrawData) drawAreas(areaDrawData, map)
        if (detectedRegionData) drawaDetectedRegions(detectedRegionData, map)
        var CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20,
            opacity: 0.7
        }).addTo(map);
    }
    const getImages = () => {
        API.getImageCollectionImages(props.task.image_collection_id, " ", 1, 0).then(data => {
            if (data.images.length > 0) {
                getAnnotation(data.images[0])


            }
        })
    }
    useEffect(() => {
        getImages()
    }, [])


    const getAnnotation = (image) => {
        API.getAnnotation(image.file_name, image.collection_id, props.task.annotation_set.id).then(data => {
            let regionsData = data.data.annotationData.features.map((obj) => {
                return ({
                    id: obj.properties.id,
                    className: obj.properties.className,
                    coordinates: obj.geometry.coordinates[0].map(latLng => {
                        return {
                            lat: latLng[0],
                            lng: latLng[1]
                        }
                    }),
                    type: obj.properties.type || obj.geometry.type,
                    color: props.task.classes.find((cls) => cls.name === obj.properties.className).color
                })
            })

            if (data.data.annotationData.accuracyAreaDetections) setDetectedRegionData(data.data.annotationData.accuracyAreaDetections)
            console.log("tarining detection result", data.data.annotationData)

            setRegionDrawData(regionsData)
            setAreaDrawData([...(data.data.annotationData.trainingAreas || []), ...(data.data.annotationData.testingAreas || []), ...(data.data.annotationData.accuracyAreas || [])])
            setImage(image)
            let classLabels = regionsData.map(region => {
                return { type: region.className, color: region.color }
            })
            props.setLegendDetails(prev => {
                return { ...prev, totalAnnotations: regionsData.length, trainingAreaColor: data.data.annotationData.trainingAreas?.[0]?.color, testingAreaColor: data.data.annotationData.testingAreas?.[0]?.color, accuracyAreaColor: data.data.annotationData.accuracyAreas?.[0]?.color, classLabels, detectorAccuracy: typeof (data.data.annotationData.detectorAccuracy) === "number" ? data.data.annotationData.detectorAccuracy + "%" : "N/A" }
            })

        })

    }
    const drawRegions = async (drawingRegions, map) => {

        drawingRegions.map(async (region, index) => {
            if (region.type === POLYGON) {
                let polygon = new L.Polygon(region.coordinates, {
                    name: region.id,
                    color: region.color,
                    fillColor: region.color,
                    pane: "annotation",
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(map)
            }
            if (region.type === BOX) {
                let box = new L.Rectangle(region.coordinates, {
                    name: region.id,
                    pane: "annotation",
                    color: region.color,
                    fillColor: region.color,
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(map)
            }
            if (region.type === CIRCLE) {
                let circle = new L.Circle(region.coordinates.latLng, region.coordinates.radius, {
                    name: region.id,
                    color: region.color,
                    pane: "annotation",
                    fillColor: region.color,
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(map)
            }
        })

    }
    const drawAreas = async (drawingRegions, map) => {
        drawingRegions.map(async (region, index) => {

            let polygon = new L.Polygon(region.coordinates, {
                name: region.id,
                color: region.color,
                fillColor: region.color,
                pane: "accuracyAreas",
                fillOpacity: 0.1,
                weight: 3,
                dashArray: '5,5',
                dashOffset: '20'
            })
            polygon.addTo(map)
        })
    }


    const drawaDetectedRegions = async (drawingRegions, map) => {
        var pattern = new L.StripePattern({ opacity: 1, color: "red", weight: 2, spaceWeight: 1 });
        pattern.addTo(map)
        drawingRegions.map(async (region, index) => {
            if (region.type === POLYGON) {
                let polygon = new L.Polygon(region.coordinates, {
                    name: region.id,
                    color: "red",
                    fillPattern: pattern,
                    pane: "annotation",
                    fillOpacity: 1,
                    weight: 3,
                }).addTo(map)
            }
            if (region.type === BOX) {
                let box = new L.Rectangle(region.coordinates, {
                    name: region.id,
                    pane: "annotation",
                    color: "red",
                    fillPattern: pattern,
                    fillOpacity: 1,
                    weight: 3,
                }).addTo(map)
            }
            if (region.type === CIRCLE) {
                let circle = new L.Circle(region.coordinates.latLng, region.coordinates.radius, {
                    name: region.id,
                    color: "red",
                    fillPattern: pattern,
                    pane: "annotation",
                    fillOpacity: 1,
                    weight: 3,
                }).addTo(map)
            }
        })
    }

    return (
        <div style={{ width: "100%", height: "100%", padding: "10px", background: "white" }}>
            {image ? <MapAnnotation
                user={props.user}
                permissions={props.permissions}
                selectedImageForAnnotation={image}
                onOrthoLoad={(map) => mapInitialized(map)} /> : <LoaderComponent />}
        </div>
    )
}

export default TrainingDetectionResult