import React, { Component } from "react";
import * as API from "../../api.js";
import closeButton from "../../close-button.png";
import { Modal } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AnimatedModal } from "../../ReusableComponents/AnimatedModal";
import { SetRestrictSesssionExpire } from "../../helperComponents/OnIdleHandlerWrapper";
import AppConstants from "../../AppConstants";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Plus from "../../icons/addBlue.png";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon";
import GoogleDriveFilePicker from "../../ReusableComponents/GoogleDriveFilePicker";
import { isSelectedDataExceedStorageLimit, convertInMbGB, isTiffMultiSpectral } from "../../ReusableComponents/reusableFunctions";
import { StorageLimitExceed, getStorageUserByService } from "../../subscription/UserSubscription";
let prevLoadedData = 0
let chunkSize = 10 * 1024 * 1024;
export default class AeroCaptureMultiFileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      randomString: this.randomString(16),
      targetFilesArr: [],
      uploaded_data: [],
      urls: {},
      flights: [],
      imageLimitCount: "0",
      imagePerSeconds: 1,
      seconds: [1, 2, 3, 4, 5],
      totalImageCount: parseInt(this.props.totalImageCount),
      collection: this.props.collection,
      progress: 0,
      _progressInfos: [],
      toShowProgressOf: 0,
      show: false,
      uploadedCount: 0,
      totalImages: 0,
      isOverwrite: false,
      isCancel: false,
      showCancelPopup: false,
      cancelledAt: 0,
      action: 0,
      activityPopupObj: undefined,

      currentFileIndex: null,
      lastUploadedFileIndex: null,
      currentChunkIndex: null,
      uploadedData: 0,
      uploadFileSize: 0,
      showStorageExceedPopup: false,
    };
  }

  cancelUpload = () => {
    this.setState({
      isCancel: true,
      targetFilesArr: [],
      uploaded_data: [],
    }, () => {
      this.setState({
        showCancelPopup: false,
        show: false,
        toShowProgressOf: 0,
        lastUploadedFileIndex: null,
        uploadedCount: 0,
        currentChunkIndex: null,
        currentFileIndex: null,
        uploadCheckPopup: false
      })
    })
    this.props.onImageUpload(100)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentChunkIndex !== this.state.currentChunkIndex && this.state.currentChunkIndex !== null && !this.state.isCancel) {
      this.readAndUploadCurrentChunk();
    }

    if (prevState.currentFileIndex !== this.state.currentFileIndex) {
      if (this.state.currentFileIndex !== null) {
        this.setState({ currentChunkIndex: 0 })
      }
    }

    if (prevState.lastUploadedFileIndex !== this.state.lastUploadedFileIndex) {
      if (this.state.lastUploadedFileIndex === null) {
        return;
      }
      const isLastFile = this.state.lastUploadedFileIndex === this.state.targetFilesArr.length - 1;
      const nextFileIndex = isLastFile ? null : this.state.currentFileIndex + 1;
      this.setState({ currentFileIndex: nextFileIndex })
    }
  }

  readAndUploadCurrentChunk = () => {
    const reader = new FileReader();
    const file = this.state.targetFilesArr[this.state.currentFileIndex];
    if (!file) {
      return;
    } else {
      chunkSize = chunkSize > file.size ? file.size : chunkSize
      const from = this.state.currentChunkIndex * chunkSize;
      const to = from + chunkSize;
      const blob = file.slice(from, to);
      this.uploadChunk(blob, file.name, file.size, this.state.currentChunkIndex, this.state.currentFileIndex);
    }
  }

  uploadChunk = (readerEvent, fileName, fileSize, currentChunkIndex, currentFileIndex) => {
    const data = new FormData()
    data.append('file', readerEvent)
    data.append('name', this.state.keepBoth ? this.state.randomString + "_" + fileName : fileName);
    data.append('size', fileSize);
    data.append('currentChunkIndex', currentChunkIndex);
    data.append('totalChunks', Math.ceil(fileSize / chunkSize));
    data.append('serviceName', this.props.serviceName)
    data.append('collection_id', this.state.collection?.id);
    data.append('imageType', this.props.acceptFileType === AppConstants.FILETYPES.TIF ? 'orthomosaic' : this.props.imageType ? this.props.imageType : 'normal')
    if (this.props.floorId) data.append('floorId', this.props.floorId)
    if (this.props.planId) data.append('planId', this.props.planId)
    if (this.props.isResize) data.append('isResize', this.props.isResize);

    API.uploadImages(data).then(response => {
      response.json().then((res) => {
        const chunks = fileSize / chunkSize;
        if (response.status === 200 && !this.state.isCancel) {
          if (currentChunkIndex === Math.ceil(chunks - 1)) {
            chunkSize = 10 * 1024 * 1024
            prevLoadedData = 0
            this.setState({
              uploadedData: fileSize,
              uploadFileSize: fileSize,
              lastUploadedFileIndex: currentFileIndex,
              randomString: this.randomString(16),
              currentChunkIndex: null,
              toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
              uploadedCount: (currentFileIndex + 1)
            }, () => {
              if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                SetRestrictSesssionExpire(false);
                this.props.onImageUpload(100);
              }
            })
          } else {
            let fileUploadedPercentage = currentChunkIndex / chunks * 100
            this.setState({
              uploadedData: this.state.uploadedData + chunkSize,
              uploadFileSize: fileSize,
              toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
            }, () => {
              this.setState({ currentChunkIndex: currentChunkIndex + 1 })
              prevLoadedData = fileUploadedPercentage;
              console.log("Upload % : ", this.state.toShowProgressOf);
              if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                console.log("this is minus value");
                prevLoadedData = 0
                this.setState({ toShowProgressOf: 0 });
              }
            })
          }
        } else if (response.status == 500) {
          this.readAndUploadCurrentChunk();
        } else {
          this.state.isCancel ? this.cancelUpload() : this.setUploadFailedError(res.message || "something went wrong while uploading image.", true)
        }
      })
    }).catch((err) => {
      SetRestrictSesssionExpire(false)
      this.setState({
        isCancel: true,
        showCancelPopup: false,
        show: false,
        targetFilesArr: [],
        toShowProgressOf: 0,
        lastUploadedFileIndex: null,
        uploadedCount: 0,
        currentChunkIndex: null,
        currentFileIndex: null,
        uploadCheckPopup: false,
        activityPopup: {
          icon: "ERROR",
          msg: "Can not connect!",
        }
      })
    })
  }
  //To call check upload API and get list of already uploaded images 

  check_upload_status = async (collection_id, files_array) => {
    let f_names = files_array.map((element) => { return element.name })

    await API.checkUploadStatus(
      collection_id,
      f_names,
      (event) => { }
    ).then(
      (data) => {
        if (data.uploaded.length === 0) {
          this.setState((state) => ({
            ...state,
            show: true,
          }), () => {
            this.uploadFiles()
          });
        } else {
          this.setState((state) => ({
            ...state,
            uploadCheckPopup: true,
            uploaded_data: data.uploaded,
          }));
        }

      },
      (e) => {
        SetRestrictSesssionExpire(false)
        console.log(e);
      }
    );
  }

  checkDuplicateFloorImages = async (files_array) => {
    try {
      let floorId = this.props.floorId;
      let f_names = files_array.map((element) => { return element.name })

      let data = await API.checkDuplicateFloorImage(floorId, f_names, this.props.imageType)

      if (data.uploaded.length === 0) {
        this.setState((state) => ({
          ...state,
          show: true,
        }), () => {
          this.uploadFiles()
        });
      } else {
        this.setState((state) => ({
          ...state,
          uploadCheckPopup: true,
          uploaded_data: data.uploaded,
        }));
      }
    }
    catch (error) {
      SetRestrictSesssionExpire(false)
      console.log("erro :", error)
    }
  }


  randomString(length) {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
  }

  // Function to execute when overwrite option is selected
  setUploadFailedError = (msg, showPoup) => {
    console.log("showPoup : ", msg)
    this.setState({
      activityPopup: !showPoup ? undefined : {
        msg: msg,
        item: "Upload Failed !",
        icon: "ERROR",
        action: "COMPLETE",
      },
      show: false,
      isCancel: true,
      showCancelPopup: false,
      targetFilesArr: [],
      toShowProgressOf: 0,
      lastUploadedFileIndex: null,
      uploadedCount: 0,
      fileUploadedPercentage: 0,
      currentChunkIndex: null,
      currentFileIndex: null,
      uploadCheckPopup: false
    })
  }


  uploadFiles = async () => {
    if (this.state.targetFilesArr.length > 0) {
      if (this.state.currentFileIndex === null) {
        SetRestrictSesssionExpire(true)
        this.setState({
          currentFileIndex: this.state.lastUploadedFileIndex === null ? 0 : this.state.lastUploadedFileIndex + 1,
          toShowProgressOf: 0,
          show: true,
          uploadCheckPopup: false,
          activityPopup: undefined,
          isCancel: false,
          uploadedCount: 0
        });
      }
    }
  }

  getFilteredFiles = (files) => {
    return (
      this.props.acceptFileType === AppConstants.FILETYPES.PNGJPEG ?
        files.filter((file) => (file.name.toLowerCase()).includes("jpeg") || (file.name.toLowerCase()).includes("jpg") || (file.name.toLowerCase()).includes("png")) :
        (this.props.acceptFileType === AppConstants.FILETYPES.PNGJPEGTIF ? files.filter((file) => (file.name.toLowerCase()).includes("tif") || (file.name.toLowerCase()).includes("tiff") || (file.name.toLowerCase()).includes("jpeg") || (file.name.toLowerCase()).includes("jpg") || (file.name.toLowerCase()).includes("png"))
          : files.filter((file) => (file.name.toLowerCase()).includes("tiff") || (file.name.toLowerCase()).includes("tif")))
    )
  }

  onChangeListenerFile = async (event) => {
    event.persist();
    const _files = Array.from(event.target.files);
    const tiffFiles = _files.filter(file => file.type === "image/tiff" || file.type === "image/tif");

    if (this.props.serviceName === AppConstants.SERVICES.DRONENAKSHA && tiffFiles.length > 0) {
      if (!await isTiffMultiSpectral(tiffFiles)) {
        this.setState({
          activityPopup: {
            icon: "ERROR",
            msg: "Invalid tiff images found!",
          }
        });
        return;
      }
    }

    let targetFilesArr = this.props.acceptFileType ? this.getFilteredFiles(_files) : _files;
    this.onClickListenerFile(event);
    if (targetFilesArr.length > 0) {
      if (await isSelectedDataExceedStorageLimit(targetFilesArr, this.props.serviceName)) {
        this.setState({ targetFilesArr }, async () => {
          if (navigator.connection && navigator.connection.downlink) {
            chunkSize = Math.round(navigator.connection.downlink * 1024 * 1024);
          }
          else { chunkSize = 1024 * 1024; } // Default value: 1 MB
          if (this.props.imageType === undefined) {
            this.check_upload_status(this.state.collection.id, targetFilesArr, chunkSize);
          } else {
            this.checkDuplicateFloorImages(targetFilesArr);
          }
        });
      } else {
        this.setState({ showStorageExceedPopup: true });
      }
    }
  };

  onClickListenerFile = (event) => {
    event.target.value = ''
    this.setState({
      uploadedData: 0,
      uploadFileSize: 0,
    })
  }

  close() {
    if (this.state.uploadedCount !== this.state.targetFilesArr.length) {
      this.setState((prevState) => ({
        ...prevState,
        showCancelPopup: true
      }));
    } else {
      this.setState({
        show: this.state.toShowProgressOf >= 100 ? false : true,
      }, () => {
        setTimeout(() => {
          this.setState((prevState) => ({
            ...prevState,
            targetFilesArr: [],
            toShowProgressOf: 0,
            lastUploadedFileIndex: null,
            uploadedCount: 0,
            currentChunkIndex: null,
            currentFileIndex: null,
            keepBoth: false,
          }), () => {
            if (this.props.startJoyrideTour && this.state.toShowProgressOf >= 100) setTimeout(() => {
              this.props.startJoyrideTour()
            }, 2000)
          })
        }, 1000)
      });
    }

  }

  hide() {
    this.setState((prevState) => ({
      ...prevState,
      show: this.state.toShowProgressOf >= 100 ? false : true,
    }));
  }
  changeSeconds = (e) => {
    let imagePerSeconds = e.target.value;
    this.setState((state) => ({
      ...state,
      imagePerSeconds: imagePerSeconds,
    }));
  };

  componentWillUnmount() {
    SetRestrictSesssionExpire(false)
  }

  componentWillMount() {
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = ".popup-content { width: 30% !important; }";
    document.getElementsByTagName("head")[0].appendChild(style);
    let { urls, progress } = this.state;

    this.setState((state) => ({
      ...state,
      urls,
      progress,
    }));
  }
  render() {
    const handleClick = (event) => {
      if (!this.state.show) document.getElementById("hiddenFileInput").click();
    };
    const modalStyle = {
      content: {
        width: '80%'
      },
    };
    return (
      <React.Fragment>

        {/* {this.props.openUploader ? handleClick() : <></>} */}
        {/* <div>
          <div className="services-content-title">{`Total images: ${this.state.totalImageCount}`}</div>
          <div
            style={{
              marginLeft: "40px",
              width: "fit-content",
              height: "0",
            }}
          ></div>
        </div> */}
        <GoogleDriveFilePicker openImportPopup={this.props.openImportPopup} callFromOutSide={this.props.startGoogleDriveSelection} clearCallFromOutSide={this.props.clearGoogleDriveStates} dataRequiredToSave={{ collection_id: this.state.collection?.id, planId: this.props.planId }} onImageUpload={this.props.onImageUpload} />
        {/* image import from selection */}

        <div
          className="add-user-form-text-wrapper"
          style={{ marginTop: "0px", display: this.props.hide ? "none" : undefined }}
        >
          <input
            name="company_logo"
            id="hiddenFileInput"
            type="file"
            className="add-user-form-file"
            required
            onClick={(event) => this.onClickListenerFile(event)}
            onChange={this.onChangeListenerFile}
            accept={this.props.acceptFileType || "image/*"}
            multiple={!(this.props.acceptSingleFileOnly)}
            style={{ display: "none" }}
          />
          {!this.props.uploadBtnNotRequired && <ButtonWithIcon
            text={"Images"}
            icon={Plus}
            onClick={handleClick}
            isDisabled={this.props.isDisabled}
          />}
        </div>

        { /* ------------ Storage Exceed Popup ----- */}
        {
          this.state.showStorageExceedPopup ?
            <StorageLimitExceed
              seletedDataSize={null} totalStorage={null}
              onClickOk={() => {
                this.setState({ showStorageExceedPopup: false }, () => {
                  this.setUploadFailedError("something went wrong while uploading image.", false)
                })
              }} />
            : <></>
        }
        {/* ------------- Storage Exceed Popup END------- */}

        {/* -------------Image-upload-Limit---------- */}
        <Modal
          width={'80%'}
          show={this.state.imageLimitPopup}
          onHide={() => {
            this.setState((state) => ({
              ...state,
              imageLimitPopup: false,
            }))
          }}
          className="modal-dialog-centered"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>You reach 25 images upload limit</div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="add-user-form-submit-button ok-button btn-ok"
              style={{ width: "fit-content" }}
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  imageLimitPopup: false,
                }));
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        {/*--------------------- image upload limit count ----------------------*/}


        <Modal
          show={this.state.uploadCheckPopup}
          onHide={() =>
            this.setState((state) => ({
              ...state,
              uploadCheckPopup: false,
            }))
          }
          className="modal-dialog-centered"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <img
            id="close-popup"
            src={closeButton} style={{
              width: "auto",
              height: "20px",
              position: "absolute",
              top: "-8px",
              right: "-8px",
              cursor: "pointer"

            }} onClick={() => {
              var style = document.createElement('style');
              style.type = 'text/css';
              style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px;}';
              this.setState((state) => ({
                ...state,
                uploadCheckPopup: false,
              }));

            }} />
          <Modal.Body>
            <div>{this.state.uploaded_data.length}/{this.state.targetFilesArr.length} file(s) were found to be duplicate, what would you like to do ?</div>

          </Modal.Body>
          <Modal.Footer>
            <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '34px', paddingRight: '34px', cursor: 'pointer' }}
              onClick={async () => {
                this.setState({
                  keepBoth: false,
                  activityPopup: undefined,
                  targetFilesArr: await this.state.targetFilesArr.filter(file => !(this.state.uploaded_data.includes(file.name)))
                }, () => {
                  if (this.state.targetFilesArr.length > 0) {
                    this.uploadFiles()
                  } else {
                    this.cancelUpload()
                  }
                })
              }} >Skip</div>

            <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '20px', paddingRight: '20px', cursor: 'pointer' }} onClick={() => {
              this.setState((state) => ({
                ...state,
                activityPopup: undefined,
                uploadCheckPopup: false,
                show: true,
                keepBoth: false,
                isOverwrite: true,
                action: 2,
              }), () => {
                this.uploadFiles()
              })
            }} >Overwrite</div>

            <button
              className="add-user-form-submit-button ok-button btn-ok"
              style={{ width: "fit-content", padding: "8px 20px 8px 20px" }}
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  activityPopup: undefined,
                  uploadCheckPopup: false,
                  show: true,
                  keepBoth: true,
                  action: 3
                }), this.uploadFiles);
              }}
            >
              Keep both
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.videoProgressPopup}
          onHide={() =>
            this.setState((state) => ({
              ...state,
              videoProgressPopup: false,
            }))
          }
          // size="md"
          className="modal-dialog-centered"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            {
              <>
                {this.state._progressInfos.length &&
                  this.state._progressInfos[0] ? (
                  <ul type="none" style={{ paddingLeft: "unset" }}>
                    <li style={{ fontSize: "14px", marginBottom: "8px" }}>
                      <>
                        {this.state._progressInfos[0].percentage == 100
                          ? "Uploaded"
                          : "Uploading"}
                      </>{" "}
                      {this.state._progressInfos[0].fileName.length > 35
                        ? `${this.state._progressInfos[0].fileName.substring(
                          0,
                          34
                        )}...`
                        : `${this.state._progressInfos[0].fileName}`}
                    </li>
                    <li>
                      <div className="progress-bar-container">
                        <div
                          className="progress-bar-bar"
                          style={{
                            width: `${this.state._progressInfos[0].percentage}%`,
                            display: "flex",
                          }}
                        ></div>
                      </div>
                      <div className="progress-bar-text">{`${this.state._progressInfos[0].percentage}%`}</div>
                      {/* <div className="progress-bar-text">{`${this.state.progress}%`}</div> */}
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <button
              //  id="upload-modal-ok"
              className="add-user-form-submit-button ok-button btn-ok"
              // disabled
              style={{ width: "fit-content" }}
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  videoProgressPopup: false,
                }));
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        {this.state.activityPopup ? <ActivityPopup
          item={this.state.activityPopup.item}
          open={this.state.activityPopup}
          icon={this.state.activityPopup.icon}
          action={this.state.activityPopup.action}
          msg={this.state.activityPopup.msg}
          close={() => this.setState({ activityPopup: undefined })}
        // onClickOk={() => this.deleteSharedData()}
        /> : <></>}

        <AnimatedModal
          isOpen={this.state.show}
          onRequestClose={() => this.hide()}
          height="300px"
          width="450px"
        >
          <div>
            <div className="services-content-title" style={{ textAlign: 'center', marginTop: '15px' }}>{this.state.toShowProgressOf == 100 ? "Upload Completed !" : "Uploading Images..."}</div>
            <div style={{}}>
              <div style={{ textAlign: 'center', marginTop: "4%", marginBottom: "2%" }}>
                <div style={{ width: 175, height: 175, margin: 'auto', fontSize: "10px" }}>
                  <CircularProgressbar
                    value={this.state.toShowProgressOf}
                    text={this.props.acceptFileType == AppConstants.FILETYPES.TIF ? `${convertInMbGB(this.state.uploadedData)}/${convertInMbGB(this.state.uploadFileSize)}` : `${this.state.uploadedCount}/${this.state.targetFilesArr.length}`}
                    styles={{
                      text: this.props.acceptFileType == AppConstants.FILETYPES.TIF ? {
                        fill: '#2989cf',
                        fontSize: '9px',
                      } : {
                        fill: '#2989cf',
                        fontSize: this.state.targetFilesArr.length > 99999 ? '9px' : this.state.targetFilesArr.length > 9999 ? '11px' : this.state.targetFilesArr.length > 999 ? '13px' : '15px',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            //  id="upload-modal-ok"
            className="add-user-form-submit-button ok-button btn-ok popup-btn-right"
            // disabled10px
            style={{ width: "fit-content", marginTop: '4%', marginRight: '6%' }}
            onClick={() => this.close()}
          >
            {this.state.uploadedCount === this.state.targetFilesArr.length ? "Close" : "Cancel"}
          </button>
        </AnimatedModal>

        <Modal
          style={{ marginTop: '150px' }}
          show={this.state.showCancelPopup}
          onHide={() => {
            this.setState((state) => ({
              ...state,
              showCancelPopup: false,
            }));
          }}
        >
          <Modal.Body >
            <p>Your file upload is still in progress, Are you sure you want to cancel it?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showCancelPopup: false,
                    show: true
                  }));
                }}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="add-user-form-submit-button popup-btn-right btn-ok"
                onClick={() => {
                  this.cancelUpload()
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

}
